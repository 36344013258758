// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  AttendeeDiversityChart,
  CommonEventsPageInput,
  EventRegistrationDetailsInput,
  EventsAttendeesGrowthInput,
  EventsLeaderboardInput,
  EventsOverviewEventsListInput,
  eventsProjectsList,
  EventsTableDetails,
  EventViewDetailsSummary,
  EventViewDetailSummaryInput,
} from '@lfx/config';
import { EventDetails, EventSpeaker } from '@models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EventServiceGql } from '../gql/services/event.service.gql';
import { EventsDataGrouped } from '../models/events-speakers';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(
    private httpClient: HttpClient,
    private eventServiceGql: EventServiceGql
  ) {}

  public getEventDetails(): Observable<EventDetails> {
    const route = `${environment.apiUrl}/dashboard/events/events-details`;

    return this.httpClient.get<EventDetails>(route);
  }

  public getDateRangeText(dateRange): string {
    let startDate = '-';
    let endDate = '-';

    if (dateRange === 'alltime') {
      return 'All Time Data';
    }

    startDate = new Date(dateRange, 0, 1).toDateString();
    endDate = new Date(dateRange, 11, 31).toDateString();

    return `Data from ${startDate} to ${endDate}`;
  }

  public getAverageFromOtherOrgs(
    averageFromOtherOrgsList: number[][],
    chartLabels: string[]
  ): number[] {
    // calculation is as provided in CD-76
    const result = [];

    if (!averageFromOtherOrgsList.length) {
      return chartLabels.map(() => 0);
    }

    for (let labelIndex = 0; labelIndex < chartLabels.length; labelIndex++) {
      let averageSum = 0;
      let sumOfRecords = 0;

      for (const averageFromOtherOrgs of averageFromOtherOrgsList) {
        averageSum =
          averageSum +
          averageFromOtherOrgs[labelIndex] *
            this.getNumberOfRecords(averageFromOtherOrgs);
        sumOfRecords =
          sumOfRecords + this.getNumberOfRecords(averageFromOtherOrgs);
      }
      result.push(
        sumOfRecords === 0 ? 0 : Math.round(averageSum / sumOfRecords)
      );
    }

    return result;
  }

  public getAllPlannedEvents() {
    return this.eventServiceGql.getAllPlannedEvents(false);
  }

  public getEventAttendeesParticipation(
    organizationId: string,
    dateRange: string
  ) {
    return this.eventServiceGql.getEventAttendeesParticipation(
      organizationId,
      dateRange
    );
  }

  public getAttendeeDiversityChart(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<AttendeeDiversityChart> {
    return this.eventServiceGql.getAttendeeDiversityChart(
      organizationId,
      dateRange,
      noCache
    );
  }

  public getSpeakerDiversityChart(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<AttendeeDiversityChart> {
    return this.eventServiceGql.getSpeakerDiversityChart(
      organizationId,
      dateRange,
      noCache
    );
  }

  public getAttendeesFromMyOrganizationTableData(
    companyId: string,
    dateRange: string
  ): Observable<EventsTableDetails[]> {
    return this.eventServiceGql
      .getOrgEventsTableData(companyId, dateRange)
      .pipe(map(data => data.attendeesFromMyOrg));
  }

  public getSpeakersFromMyOrganizationTableData(
    companyId: string,
    dateRange: string
  ): Observable<EventsTableDetails[]> {
    return this.eventServiceGql
      .getOrgEventsTableData(companyId, dateRange)
      .pipe(map(data => data.speakersFromMyOrg));
  }

  public getEventViewDetailsSummary({
    input,
    noCache = false,
  }: {
    input: EventViewDetailSummaryInput;
    noCache?: boolean;
  }): Observable<EventViewDetailsSummary[]> {
    return this.eventServiceGql.getEventViewDetailsSummary({ input, noCache });
  }

  public getOrgEventsSpeakersGrouped(
    companyId: string,
    dateRange: string
  ): Observable<EventsDataGrouped[]> {
    return this.eventServiceGql.getOrgEventsSpeakersGrouped(
      companyId,
      dateRange
    );
  }

  public getAllOrgEventSpeakerDetail(
    userId: string,
    dateRange: string
  ): Observable<{ name: string; startDate: string }[]> {
    return this.eventServiceGql.getAllOrgEventSpeakerDetail(userId, dateRange);
  }

  getEventsOverviewStats(input: CommonEventsPageInput) {
    return this.eventServiceGql.getEventsOverviewStats(input);
  }

  getEventsOverviewEventsList(input: EventsOverviewEventsListInput) {
    return this.eventServiceGql.getEventsOverviewEventsList(input);
  }

  getEventsLeaderboard(input: EventsLeaderboardInput) {
    return this.eventServiceGql.getEventsLeaderboard(input);
  }

  getEventRegistrationsDetails(input: EventRegistrationDetailsInput) {
    return this.eventServiceGql.getEventRegistrationsDetails(input);
  }

  getEventsAttendeesGrowth(input: EventsAttendeesGrowthInput) {
    return this.eventServiceGql.getEventsAttendeesGrowth(input);
  }

  getEventsTravelFunding(input: CommonEventsPageInput) {
    return this.eventServiceGql.getEventsTravelFunding(input);
  }

  getEventsSponsorship(input: CommonEventsPageInput) {
    return this.eventServiceGql.getEventsSponsorship(input);
  }

  getEventSpeakers(
    eventId: string,
    organizationId: string,
    isAllOrgs = false,
    projectId?: string
  ): Observable<EventSpeaker[]> {
    const route = `${environment.apiUrl}/events/${eventId}/speakers`;

    let params = new HttpParams()
      .set('organizationId', organizationId)
      .set('isAllOrgs', isAllOrgs.toString());

    if (projectId) {
      params = params.set('projectId', projectId);
    }

    return this.httpClient.get<EventSpeaker[]>(route, { params });
  }

  private getNumberOfRecords(recordList: number[]): number {
    return recordList.reduce((a, b) => (b === 0 ? a : a + 1), 0);
  }

  getEventsProjectsList(organizationId, dateRange, isAllOrgs, tabName) {
    return this.httpClient.get<eventsProjectsList[]>(
      `${environment.apiUrl}/events/projects-list`,
      {
        params: { organizationId, dateRange, isAllOrgs, tabName },
      }
    );
  }
}
