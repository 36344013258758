// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export const generalConstants = {
  defaultOrgID: '0014100000Te02DAAR', // Google
  defaultOrgName: 'company',
  claGeneralErrorMessage: 'No CLA Data Available',
  organizationHeader: {
    orgProfileEditConflictMessage:
      'The Company domain name is already associated with another Company',
    updateCompanyInformationSuccessMessage:
      'Organization Information updated successfully',
  },
  accountSettingsTitle: 'Account Settings',
  communitySupportedProjects: 'Community Projects',
  linuxFoundationProjectName: 'The Linux Foundation',
  linuxFoundationId: 'a0941000002wBz9AAE',
  linuxFoundationSlug: 'tlf',
  lfProjectsLLCSlug: 'lfprojects',
  lfProjectsLlcId: 'a092M00001IkOVVQA3',
  defaultAvatar: 'assets/img/svgs/user-solid.svg',
  other: 'Other',
  dateFormat: 'dd/MM/y',
  // Foundation Module
  category: 'Category',
  filterBy: 'Filter By',
  selectCategory: 'Select Category',
  selectFilter: 'Select Filter',
  selectProject: 'Select Project',
  mailSubject: 'Requesting Change for Community Role',
  foundationContactRoles: [
    'Representative/Voting Contact',
    'Marketing Contact',
    'Technical Contact',
    'Billing Contact',
    'PR Contact',
    'Legal Contact',
    'HL - POC',
  ],
  securityHeaders: [
    {
      name: 'Overview',
      relativeLink: 'overview',
    },
    {
      name: 'Issues',
      relativeLink: 'issues',
      permissions: {
        any: 'feature_security_project_issues',
      },
    },
    {
      name: 'Dependencies',
      relativeLink: 'dependencies',
      permissions: {
        any: 'feature_security_project_dependencies',
      },
    },
    {
      name: 'Packages',
      relativeLink: 'packages',
      permissions: {
        any: 'feature_security_project_packages',
      },
    },
  ],
  defaultEmptyUserTable: 'No Team Members Found',
  monthNames: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  adminStatus: [
    'Planned',
    'Engaged',
    'Active',
    'Sunset',
    'Exploratory',
    'Intent to Form',
    'Moving to Launch',
    'Launched',
    'Disengaged',
  ],
  sector: [
    'AI & ML',
    'Blockchain, Data & Analytics',
    'Cloud',
    'Compliance & Security',
    'Embedded & IOT',
    'Platforms',
    'Networking',
  ],
  quarters: [
    {
      name: 'Q1',
      months: [0, 1, 2],
    },
    {
      name: 'Q2',
      months: [3, 4, 5],
    },
    {
      name: 'Q3',
      months: [6, 7, 8],
    },
    {
      name: 'Q4',
      months: [9, 10, 11],
    },
  ],
  monthly: 'monthly',
  status: 'Status',
  state: 'State',
  selectState: 'Select State',
  selectStatus: 'Select Status',
  synkURL: 'https://snyk.io/vuln/',
  snykcweURL: 'https://cwe.mitre.org/data/definitions/',
  snykcveURL: 'https://cve.mitre.org/cgi-bin/cvename.cgi?name=',
  sliderRollerColor: '#C5C5C5',
  marketingColorCode: '#3FA9D2',
  mentorshipColorCode: '#FFA356',
  meetsupColorCode: '#98D377',
  travelColorCode: '#FF4A53',
  stipendColorCode: '#FFC107',
  otherColorCode: '#6C3008',
  dollarSymbol: '$',
  annualGoal: 'Annual Goal',
  shortOfGoal: '%delta% short of %total% goal',
  surpassedByTotal: 'Goal of %total% surpassed by %delta%',
  goalReached: 'Goal of %total% has been reached!',
  btnText: 'Learn More',
  defaultActionsTitle: 'Manage',
  viewProjects: 'View Projects',
  btnType: 'primary',
  currentContext: 'current-context',
  currentOrganization: 'current-organization',
  currentOrganizationB2Bid: 'current-organization-b2b-id',
  currentOrganizationSlug: 'current-organization-slug',
  accessKey: 'userAccess',
  hexaCodeValidatorRegex: /^#[0-9A-F]{6}$/i,
  incomeGLAccount: [
    '4200 Training',
    '4105 Membership Dues : Platinum Membership',
    '4110 Membership Dues : Gold Membership',
    '4115 Membership Dues : Silver Membership',
    '4120 Membership Dues : Premier Membership',
    '4125 Membership Dues : Community',
    '4126 Membership Dues : Promoter',
    '4128 Membership Dues : Supporter',
    '4129 Membership Dues : Adopter Membership',
    '4130 Membership Dues : Affiliates & Colleges',
    '4150 Membership Dues : Individual Memberships',
    '4310 Directed Funds : Directed Fund Revenue',
    '4312 Directed Funds : Directed Fund Rev. used to offset Event Mgmt exps',
    '4315 Directed Funds : Directed Fund Event Sponsorship/Registration',
    '4410 Events (non EMS) : Event Sponsor Revenue',
    '4415 Events (non EMS) : Event Registration',
    '4420 Events (non EMS) : Event Commission',
    '4530 Donations',
    '4550 Oth Income',
    '4560 Oth Income : Advertising Income',
    '4570 Oth Income : Event Management Services (non CPs)',
    '4590 Oth Income : Other Revenue',
    '4605 Collaborative Projects : Event Management Services Revenue',
    '4610 Collaborative Projects : Marketing Services Revenue',
    '4615 Collaborative Projects : IT Services Revenue',
    '4620 Collaborative Projects : Project Coordination Services Revenue',
    '4625 Collaborative Projects : License Scanning Compliance Revenue',
    '4690 Collaborative Projects : SG&A - CPs',
  ],
  deferredRevenueAccount: [
    'Deferred Revenue - Others',
    'Deferred Revenue - Memberships',
    'Deferred Revenue - Direct Funds',
  ],
  department: ['Events', 'Sales', 'Training', 'Training (Linux Labs)'],
  urlRegex:
    /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
  orgUrlRegex:
    /^(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
  employeesRegex: /^\d{1,7}$/,
  crunchBaseUrlRegex: /^https:\/\/crunchbase.com\//,
  defaultLocale: 'en-US',
  dateFormatForDateFormatPipe: 'mediumDate',
  timeFormatForDateFormatPipe: 'mediumTime',
  dateTimeFormatForDateFormatPipe: 'medium',
  dataGridTypes: {
    date: 'date',
    time: 'time',
    dateTime: 'datetime',
    currency: 'currency',
  },
  pdfModalComponentConstants: {
    title: 'Document',
    closeText: 'Close',
    buttonText: 'Review Document',
    pdfTextSize: 'xl',
  },
  adminFoundationSections: [
    {
      name: 'Essentials',
      icon: '',
      state: '',
    },
    {
      name: 'Legal',
      icon: 'fas fa-gavel',
      state: 'Pending',
    },
    {
      name: 'Finance',
      icon: 'fas fa-hand-holding-usd',
      state: 'Incomplete',
    },
    {
      name: 'Membership',
      icon: 'fas fa-user-circle',
      state: 'Attention',
    },
    {
      name: 'User Groups',
      icon: 'fas fa-users',
      state: 'Incomplete',
    },
    {
      name: 'Managed Services',
      icon: 'fas fa-cogs',
      state: 'Incomplete',
    },
    {
      name: 'IT Services',
      icon: 'fas fa-cog',
      state: 'Incomplete',
    },
  ],
  adminProjectSections: [
    {
      name: 'User Groups',
      icon: 'fas fa-users',
      state: 'Incomplete',
    },
    {
      name: 'Managed Services',
      icon: 'fas fa-cogs',
      state: 'Incomplete',
    },
    {
      name: 'IT Services',
      icon: 'fas fa-cog',
      state: 'Incomplete',
    },
  ],
  numberWidgetDefaultFontSize: 70,
  regexPhoneNumber: /[0-9\+\-\ ]/,
  regexReplace: /^\s+|\s+$/g,
  regexCharacters: /[^a-z0-9 -]/g,
  routerForRedirectForbidden: '/',
  securitySeverity: ['All', 'High', 'Medium', 'Low'],
  securityStatus: ['All', 'Open', 'Close'],
  resourceTypes: {
    audio: 'audio',
    video: 'video',
    pdf: 'pdf',
    image: 'image',
    link: 'link',
    doc: 'doc',
    code: 'code',
    archive: 'archive',
    other: 'other',
  },
  foundation: 'Foundation',
  projectGroup: 'Project Group',
  project: 'Project',
  foundationDefaultIcon: 'fas fa-brackets-curly',
  projectDefaultIcon: 'fas fa-code',
  noDescriptionText: 'No description available',
  noDataPlaceholder: '—',
  defaultFoundationColor: '#cccccc',
  viewProjectsDetails: 'View Details',
  lfSponsored: 'LF Supported',
  summaryTableHeader: [
    'projects',
    'high severity',
    'medium severity',
    'low severity',
    'total issues',
    'updated',
  ],
  redirectUrlLocalStorageProp: 'redirectUrl',
  mockAvatar: '',
  statusOK: '200',
  updateFlag: 'update',
  addNewCLAManagerText:
    'A request has been sent to NAME to create an LF Login. They must create an LF Login before they can be added as a CLA Manager.',
  addCLAManagerText: ' NAME has been added as a CLA Manager for PROJECTNAME.',
  loader: '/assets/img/spinners/spinner.svg',
  claSignatory: 'cla-signatory',
  claManager: 'cla-manager',
  claManagerDesignee: 'cla-manager-designee',
  excludedRolesForOrgScope: [
    'cla-manager',
    'cla-signatory',
    'cla-manager-designee',
    'company-admin',
    'project-manager',
    'community-program-manager',
  ],
  emailRegex:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  fullNameRegex: /^(?:[a-zA-Z]['.-]? ?)+$/,
  usernameRegex: /^[a-z][a-z0-9.]+[a-z0-9]$/,
  nameRegex: /^[aA-zZ]'?[- a-zA-Z]+$/,
  domainREgex: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/,
  githubUsernameRegex: /^[a-z\d](?:[a-z\d]|[-_](?=[a-z\d])){0,38}$/i,
  claManagerAddFailed: 'Failed to add a new cla manager',
  claManagerSuccessIcon: 'fa-check-circle',
  claManagerFailIcon: 'fa-exclamation-circle',
  communityMemberRole: {
    initEmptyProjectMembers:
      'This organization does not have any committee members yet',
    emptyProjectMembers: 'No results were found',
  },
  header: {
    supportLink:
      'https://jira.linuxfoundation.org/servicedesk/customer/portal/4',
    docslink:
      'https://docs.linuxfoundation.org/lfx/organization-dashboard-pre-release',
  },
  individualNoAccount: 'Individual - No Account',
  standAloneFoundationName: 'The Linux Foundation',
  requestSupportTicketLink:
    'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/create/383',
  projectType: 'Project',
  myOrgSpeakers: 'myOrgSpeakers',
  allOrgsSpeakers: 'allOrgsSpeakers',
  myOrgAttendees: 'myOrgAttendees',
  allOrgsAttendees: 'allOrgsAttendees',
  tncLearners: 'tncLearners',
  certifiedEmployees: 'certifiedEmployees',
  silverMembershipBenefits: [
    {
      headingText: 'Events Benefits',
      items: [
        // eslint-disable-next-line max-len
        'One invite to our member-exclusive Linux Foundation Member Summit, which fosters collaboration, innovation, and partnerships among the leading projects and organizations working to drive digital transformation with open source technologies.',
        'Employees at your organization receive a 20% registration discount to eligible Linux Foundation events.',
        'A 5% Discount on Linux Foundation Event Sponsorship (7% discount on multi-event Sponsorships). Sponsorships offer a valuable way to reach the community of Open Source Developers, Engineers, C-level Executives, and more',
      ],
    },
    {
      headingText: 'Training Benefits',
      items: [
        'Ten coupon codes good for any eLearning, certification exam, or eLearning/exam bundle. Additional terms apply.',
      ],
    },
    {
      headingText: 'Legal Benefits',
      items: [
        'Your legal counsel’s ability to participate in bi-monthly legal calls',
        'One invite for your in-house counsel to LF Legal Summit, which helps connect your counsel to their peers at other member companies and advance your organization’s open source licensing, litigation, compliance, and best practices.',
      ],
    },
    {
      headingText: 'Marketing Benefits',
      items: [
        'Your company logo on the LF website',
        'Post one technical article about an open source project you care about to Linux.com, which has over 1 million unique visitors per month.',
        'Exposure through new Silver members group press release, with social media promotion.',
        'Have your company’s events featured on our events.linuxfoundation.org community events calendar: ',
      ],
    },
  ],
  benefitsCookieName: 'claimYourBenefits',
  inviteToLfxCookieName: 'inviteToLfx',
  trackActivityCookieName: 'trackYourActivity',
  keyContactCookieName: 'updateKeyContact',
  uploadYourLogoCookieName: 'uploadYourLogo',
  githubRepoRegex:
    /^(?:https?:\/\/)?(?:www.)?github.com\/([A-Za-z0-9_.-]+\/[A-Za-z0-9_.-]+)/,
};

export const extractDomain = (link: string) => {
  // e.g sony.com/en/
  const indexOfSlash = link.indexOf('/');

  return indexOfSlash === -1 ? link : link.substring(0, indexOfSlash);
};

export const allowedMembershipRolesToShow = [
  'Billing Contact',
  'Legal Contact',
  'Primary/Voting Contact',
  'Representative/Voting Contact',
  'Authorized Signatory',
  'PO Contact',
  'Technical Contact',
  'Marketing Contact',
  'PR Contact',
  'Event Sponsorship Contact',
];

export const membershipHistoryFilter = (foundationId: string) =>
  `membershipstatus in Active,At Risk,Purchased,Completed,Expired and membershiptype eq Corporate and productfamily eq Membership and projectid eq ${foundationId}`;
