// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Directive, Input, TemplateRef, ViewChild } from '@angular/core';
import { downloadCSVFile } from '@lfx/core/helper';
import { CompanyService } from '@lfx/core/services';
import { Column, DataCellV2, DataRowV2 } from '@lfx/shared/interfaces';
import {
  NgbActiveOffcanvas,
  NgbOffcanvas,
  NgbOffcanvasRef,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Directive()
export abstract class BaseListOffcanvasDirective {
  @Input() listInfo: ListOffcanvasInfo;
  @ViewChild('nameColumn', { static: true }) nameColumn: TemplateRef<any>;

  searchValue = '';
  columns: Column[] = [];
  data: DataRowV2[] = [];
  searchData: DataRowV2[] = [];
  paginatedData: DataRowV2[] = [];
  page = 1;
  pageSize = 10;
  totalSize = 0;
  offset = 0;

  offCanvasRef: NgbOffcanvasRef;
  emptyDataMessage = 'No data found';
  spinnerName = 'base-list-offcanvas-spinner';
  isLoading = false;

  constructor(
    protected activeOffcanvas: NgbActiveOffcanvas,
    protected offcanvasService: NgbOffcanvas,
    protected companyService: CompanyService,
    protected spinner: NgxSpinnerService
  ) {}

  protected abstract fetchData(): void;
  protected abstract getCSVData(): any[];
  protected abstract getColumns(): Column[];
  protected abstract getListType(): string;

  clearSearch() {
    this.searchValue = '';
    this.onSearchChange('');
  }

  setIsLoading(isLoading: boolean) {
    if (isLoading) {
      this.spinner.show(this.spinnerName);
    } else {
      this.spinner.hide(this.spinnerName);
    }

    this.isLoading = isLoading;
  }

  onSearchChange(value: string) {
    this.searchData = this.data.filter(d =>
      d.name?.text?.toLowerCase().includes(value.toLowerCase())
    );
    this.onPageChange(1);
  }

  downloadCsv() {
    const csvData = this.getCSVData();

    downloadCSVFile(csvData, `${this.getListType()}.csv`);
  }

  onPageChange(page: number) {
    this.page = page;
    this.offset = (page - 1) * this.pageSize;
    this.setPaginatedData();
  }

  protected sortDataColumn(column: Column) {
    if (!column.sortable) {
      return;
    }

    this.searchData = this.searchData.sort((a, b) => {
      const aValue = a[column.prop].sortValue || a[column.prop].text;
      const bValue = b[column.prop].sortValue || b[column.prop].text;

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return column.sortDir === 'asc' ? aValue - bValue : bValue - aValue;
      }

      return column.sortDir === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });

    this.onPageChange(1);
  }

  protected setPaginatedData() {
    const startIndex = (this.page - 1) * this.pageSize;

    this.totalSize = this.searchData.length;
    this.paginatedData = this.searchData.slice(
      startIndex,
      startIndex + this.pageSize
    );
  }

  protected formatText(
    text: string,
    subtext: string | null = null,
    url: string | null = null,
    sortValue?: string | number,
    extraData?: { [key: string]: string }
  ): DataCellV2 {
    return {
      text,
      subtext,
      url,
      sortValue,
      extraData,
    };
  }
}

export interface ListOffcanvasInfo {
  companyId: string;
  count?: number;
}
