<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->

<div class="offcanvas-container">
  <div class="close-button-box cursor-pointer" (click)="activeOffcanvas.close()">
    <i class="fal fa-times"></i>
  </div>
  <div class="main-content-box">
    <div class="main-content">
      <ng-container *ngIf="eventInfo">
        <!-- Event Header -->
        <div class="list-cell">
          <div class="list-cell-name">{{ eventInfo.eventName }}</div>
          <div class="list-cell-date">{{ eventInfo.eventDate }}</div>
        </div>

        <!-- Title and Actions -->
        <div class="title-header">
          <span class="title">
            Speaking Proposals ({{ eventInfo.count | number }})
          </span>
        </div>

        <!-- Add filter chips -->
        <div class="filter-chips-container mb-3">
          <lfx-filter-chips
            [chips]="speakerStatusChips"
            [(ngModel)]="selectedStatus"
            (ngModelChange)="onStatusChange($event)"
          ></lfx-filter-chips>
          <div class="top-right">
            <!-- Search -->
            <div class="lf-search-container">
              <span class="far fa-search cursor-pointer"></span>
              <img
                src="assets/img/svgs/normal_clear_u481.svg"
                class="clear-img"
                (click)="clearSearch()"
                *ngIf="searchValue !== ''"
              />
              <input
                type="text"
                class="form-control"
                placeholder="Search"
                [(ngModel)]="searchValue"
                (ngModelChange)="onSearchChange($event)"
              />
            </div>
            <!-- Download -->
            <div class="lf-btn-download-container">
              <i
                class="fal fa-arrow-to-bottom"
                placement="bottom"
                tooltipClass="lf-box-tooltip"
                ngbTooltip="Download CSV"
                (click)="downloadCsv()"
              ></i>
            </div>
          </div>
        </div>

        <!-- Table -->
        <div class="table-container position-relative">
          <ngx-spinner
            type="ball-clip-rotate"
            [fullScreen]="false"
            [name]="spinnerName"
            color="rgba(0,0,0,0.5)"
            bdColor="rgba(255,255,255,0.5)"
            size="default"
          ></ngx-spinner>

          <lfx-new-table
            [columns]="columns"
            [data]="paginatedData"
            [version]="2"
            class="mb-4"
            (columnSortEmmitter)="sortDataColumn($event)"
            [emptyDataMessage]="emptyDataMessage"
          >
          </lfx-new-table>
        </div>

        <!-- Pagination -->
        <lfx-ngb-pagination-wrapper
          *ngIf="paginatedData?.length"
          [pageSize]="pageSize"
          [page]="page"
          [collectionSize]="totalSize"
          (pageChange)="onPageChange($event)"
          [version]="2"
        ></lfx-ngb-pagination-wrapper>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #nameColumn let-row="row.name">
  <lfx-user-profile-cell
    [name]="row.text"
    [imageUrl]="row.extraData?.imageUrl"
    [employeeId]="row.extraData?.userId"
    [options]="{ activity: { collapsed: false, defaultFilter: 'Events' } }"
    [organizationName]="row.extraData?.organizationName"
  ></lfx-user-profile-cell>
</ng-template>
