// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const tnCTrainedEmployeesLeaderboardQuery = gql`
  query tnCTrainedEmployeesLeaderboard(
    $organizationId: String!
    $isAllOrgs: Boolean
    $timeRange: String
    $page: Int
    $limit: Int
    $projectId: String
  ) {
    getTnCTrainedEmployeesLeaderboard(
      organizationId: $organizationId
      isAllOrgs: $isAllOrgs
      timeRange: $timeRange
      page: $page
      limit: $limit
      projectId: $projectId
    ) {
      userId
      userPhotoUrl
      userFullName
      coursesEnrrolledCount
      accountName
    }
  }
`;
