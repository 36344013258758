// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { UserContext } from '../core/models/user-context';

export const pageMenus: MenuItem[] = [
  {
    icon: 'fal fa-home',
    selectedIcon: 'far fa-home',
    title: 'Home',
    context: [UserContext.Company, UserContext.Staff],
    url: '/home',
    caret: false,
    beta: false,
  },
  {
    icon: 'fal fa-users-class',
    selectedIcon: 'far fa-users-class',
    title: 'Membership',
    context: [UserContext.Company, UserContext.Staff],
    url: '/membership-summary',
    defaultTab: '/active',
    caret: false,
    permissions: {
      all: ['feature_company-dashboard'],
    },
  },
  {
    icon: 'fal fa-code',
    selectedIcon: 'far fa-code',
    title: 'Code Contributions',
    context: [UserContext.Company, UserContext.Staff],
    url: '/project-contributions',
    caret: false,
    beta: false,
    permissions: {
      all: ['feature_Beta_SideMenu_project_contributions'],
    },
  },
  {
    icon: 'fal fa-calendar-star',
    selectedIcon: 'far fa-calendar-star',
    title: 'Events',
    context: [UserContext.Company, UserContext.Staff],
    url: '/events',
    defaultTab: '/overview',
    caret: false,
    permissions: {
      all: ['feature_company-dashboard'],
    },
  },
  {
    icon: 'fal fa-chalkboard-teacher',
    selectedIcon: 'far fa-chalkboard-teacher',
    title: 'Training & Certifications',
    context: [UserContext.Company, UserContext.Staff],
    url: '/training-and-certification',
    defaultTab: '/training',
    queryParams: {
      global: false,
    },
    caret: false,
    permissions: {
      all: ['feature_company-dashboard'],
    },
  },
  {
    icon: 'fal fa-hand-holding-seedling',
    selectedIcon: 'far fa-hand-holding-seedling',
    title: 'OSPO Resources',
    context: [UserContext.Company, UserContext.Staff],
    url: '/ospo-resources',
    caret: false,
    permissions: {
      all: ['feature_company-dashboard'],
    },
  },
  {
    icon: 'fal fa-tasks',
    selectedIcon: 'far fa-tasks',
    title: 'Software Inventory',
    context: [UserContext.Company, UserContext.Staff],
    url: '/software-inventory',
    caret: false,
    permissions: {
      all: ['feature_Beta_SideMenu_software_inventory'],
    },
    notInAllOrgsRollup: true,
  },
  {
    icon: 'fal fa-users',
    selectedIcon: 'far fa-users',
    title: 'Access',
    context: [UserContext.Company, UserContext.Staff],
    caret: false,
    url: '/users',
    defaultTab: '',
    permissions: {
      all: ['feature_CompanyDashboard-NewEmployeeDesign'],
    },
  },
  {
    icon: 'fal fa-money-check-edit',
    selectedIcon: 'far fa-money-check-edit',
    title: 'Profile',
    context: [UserContext.Company, UserContext.Staff],
    caret: false,
    url: '/profile',
    permissions: {
      all: ['feature_manage-org'],
    },
  },
  {
    icon: 'fal fa-book',
    selectedIcon: 'far fa-book',
    suffixIcon: 'far fa-external-link',
    title: 'Research',
    context: [UserContext.Company, UserContext.Staff],
    caret: false,
    url: 'https://www.linuxfoundation.org/research',
    isExternalUrl: true,
    permissions: {
      all: ['feature_company-dashboard'],
    },
  },
];

export const allOrgsRollupPageMenus: MenuItem[] = pageMenus.filter(
  menu => !menu.notInAllOrgsRollup
);

export interface MenuItem {
  id?: string;
  icon?: string;
  selectedIcon?: string;
  suffixIcon?: string;
  title: string;
  url?: string;
  defaultTab?: string;
  flag?: string;
  context?: UserContext | UserContext[];
  favorite?: boolean;
  caret?: boolean;
  permissions?: MenuItemPermissions;
  submenu?: MenuItem[];
  state?: any;
  updateMenu?: boolean;
  autoComplete?: boolean;
  hoverList?: {
    text: string;
    list: {
      parent: Pick<MenuItem, 'id' | 'title' | 'url'>;
      list: Pick<MenuItem, 'id' | 'title' | 'url'>[];
    };
  };
  toolTipText?: string;
  queryParams?: { [key: string]: string | boolean | number };
  beta?: boolean;
  isExternalUrl?: boolean;
  notInAllOrgsRollup?: boolean;
}

export interface MenuItemPermissions {
  all?: string | string[];
  any?: string | string[];
  except?: string | string[];
}
