// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const tnCGrowthQuery = gql`
  query tnCGrowth(
    $organizationId: String!
    $isAllOrgs: Boolean
    $timeRange: String
    $projectId: String
  ) {
    getTnCGrowth(
      organizationId: $organizationId
      isAllOrgs: $isAllOrgs
      timeRange: $timeRange
      projectId: $projectId
    ) {
      year
      month
      spanDate
      instructionType
      coursesEnrrolledCount
    }
  }
`;
