// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  CommitteeRole,
  Company,
  DashboardAccess,
  DefaultEmployeeObject,
  EmployeeOrgAffiliation,
  Membership,
} from '@lfx/core/models';
import {
  CompanyService,
  EmployeesMediatorService,
  MembershipService,
  UserService,
} from '@lfx/core/services';
import { of, Subject } from 'rxjs';
import { groupBy } from 'lodash';
import { map as _map } from 'lodash';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { DashboardAccessPipe } from '@lfx/shared/pipes/dashboard-access.pipe';

@Component({
  selector: 'lfx-employee-profile-snapshot',
  templateUrl: './employee-profile-snapshot.component.html',
  styleUrls: ['./employee-profile-snapshot.component.scss'],
  providers: [DashboardAccessPipe],
})
export class EmployeeProfileSnapshotComponent implements OnInit, OnDestroy {
  @Input() employeeId;
  @Input() employeeEmail;
  @Input() defaultEmployeeObject: DefaultEmployeeObject;
  @Input() options: UserProfileOptions = {};
  @Input() projectId?: string;

  isLoadingEmployee = false;
  employee;

  @ViewChild('committeeProject', { static: true })
  committeeProject: TemplateRef<any>;

  @ViewChild('contributorRole', { static: true })
  contributorRole: TemplateRef<any>;

  @ViewChild('contributorProjects', { static: true })
  contributorProjects: TemplateRef<any>;

  @ViewChild('projectNameTemplate', { static: true })
  projectNameTemplate: TemplateRef<any>;

  dashboardAccess = DashboardAccess;
  committeesData = [];
  keyContactsData = [];
  keyContactsColumns = [];
  committeesColumns = [];
  contributorData = [];
  contributorColumns = [];
  canMakeUpdates$ = of(false);
  allContributions = [];
  maintainerContributions = [];
  contributorContributions = [];
  orgAffiliation: EmployeeOrgAffiliation[] = [];
  employeeHasUsername = true;
  employeeHasDashboardAccess = true;
  membershipProjects: Membership[] = [];

  organization$ = this.companyService.getMyCompany();
  organization: Company;
  private destroy$ = new Subject<void>();
  activeIds: string[] = [];

  constructor(
    private companyService: CompanyService,
    private router: Router,
    private employeesMediatorService: EmployeesMediatorService,
    private userService: UserService,
    private membershipService: MembershipService,
    public activeOffcanvas: NgbActiveOffcanvas,
    private dashboardAccessPipe: DashboardAccessPipe
  ) {}

  ngOnInit(): void {
    this.canMakeUpdates$ = this.userService
      .isCompanyAdminOrLFStaffUser()
      .pipe(
        map(
          isAdminOrStaffUser =>
            isAdminOrStaffUser && !this.companyService.isAllOrgsRollup()
        )
      );
    this.keyContactsColumns = [
      {
        name: 'Name',
        prop: 'project',
        template: this.projectNameTemplate,
      },
      {
        name: 'Contact Type',
        prop: 'description',
      },
    ];

    this.committeesColumns = [
      {
        name: 'Project',
        prop: 'project',
        template: this.committeeProject,
      },
      {
        name: 'Voting Status',
        prop: 'votingStatus',
      },
    ];

    this.contributorColumns = [
      {
        name: 'Role',
        prop: 'role',
        template: this.contributorRole,
      },
      {
        name: 'Project(s)',
        prop: 'project',
        template: this.contributorProjects,
      },
    ];

    // Set active accordion panels based on options
    if (this.options?.activity?.collapsed === false) {
      this.activeIds.push('activity-ngb-panel');
    }

    if (this.options?.access?.collapsed === false) {
      this.activeIds.push('access-ngb-panel');
    }

    if (this.options?.keyContacts?.collapsed === false) {
      this.activeIds.push('key-contacts-ngb-panel');
    }

    if (this.options?.committees?.collapsed === false) {
      this.activeIds.push('committees-ngb-panel');
    }

    if (this.options?.contributor?.collapsed === false) {
      this.activeIds.push('contributor-ngb-panel');
    }

    this.organization$.pipe(takeUntil(this.destroy$)).subscribe(
      org => {
        this.organization = org;
        this.getEmployee({
          userId: this.employeeId,
          email: this.employeeEmail,
        });
        this.getContributionDetails();
        this.getMembershipProjects();
      },
      error => {
        // eslint-disable-next-line no-console
        console.error('Error fetching organization', error);
      }
    );
  }

  getContributionDetails() {
    this.organization$
      .pipe(
        switchMap(organization =>
          this.companyService.organizationUserContributionDetails(
            organization.id,
            this.employeeId,
            this.companyService.isAllOrgsRollup()
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(
        res => {
          this.allContributions = res;

          if (this.allContributions?.length) {
            this.maintainerContributions = _map(
              groupBy(
                res.filter(contribution => contribution.isMaintainer),
                'foundationName'
              ),
              (value, key) => ({
                parentProjectName: key,
                subProjects: value,
              })
            );

            this.contributorContributions = _map(
              groupBy(
                res.filter(
                  contribution =>
                    contribution.isContributor && !contribution.isMaintainer
                ),
                'foundationName'
              ),
              (value, key) => ({
                parentProjectName: key,
                subProjects: value,
              })
            );

            this.orgAffiliation = this.allContributions[0].orgAffiliation;

            this.formatContributionsData();
          }
        },
        error => {
          // eslint-disable-next-line no-console
          console.error('Error fetching contribution details', error);
        }
      );
  }

  formatContributionsData() {
    if (this.maintainerContributions.length) {
      this.contributorData.push({
        role: 'Maintainer',
        projects: this.maintainerContributions,
      });
    }

    if (this.contributorContributions.length) {
      this.contributorData.push({
        role: 'Contributor',
        projects: this.contributorContributions,
      });
    }
  }

  getEmployee({ userId = '', email = '' }) {
    this.isLoadingEmployee = true;
    this.organization$
      .pipe(
        switchMap(organization =>
          this.companyService.getOrgEmployee({
            organizationId: organization.id,
            userId,
            basic: false,
            noCache: true,
            email,
          })
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(
        res => {
          this.employee = res || { userId };
          this.employeeHasUsername = !!this.employee?.username;

          const access = this.dashboardAccessPipe.transform(this.employee);

          this.employeeHasDashboardAccess =
            access === DashboardAccess.Admin ||
            access === DashboardAccess.Viewer;

          this.isLoadingEmployee = false;

          this.employee?.engagementType
            ?.filter(
              engagement =>
                engagement.engagementType === 'Committee' &&
                engagement.appointedBy === 'Membership Entitlement'
            )
            .map(engagement => {
              const committeeRole = this.findCommitteeRole(
                engagement.projectId,
                engagement.engagementTypeId.split(':')[0]
              );

              this.committeesData.push({
                project: engagement.projectName,
                committeeName: engagement.committeeName,
                committeeId: committeeRole?.committeeId,
                committeeMemberId: committeeRole?.committeeMemberId,
                projectId: engagement.projectId,
                role: engagement.role,
                votingStatus: this.formatText(committeeRole?.votingStatus),
              });
            });

          this.employee?.engagementType
            ?.filter(engagement => engagement.engagementType === 'Membership')
            .map(engagement => {
              this.keyContactsData.push({
                project: this.formatText(engagement.projectName),
                description: this.formatText(engagement.description),
                primary: engagement.primaryContact,
                projectId: engagement.projectId,
                membershipId: engagement.engagementTypeId.split(':')[0],
                projectRoleId: engagement.engagementTypeId.split(':')[1],
              });
            });
        },
        error => {
          this.isLoadingEmployee = false;
          this.employee = { userId };
          // eslint-disable-next-line no-console
          console.error('Error fetching employee details', error);
        }
      );
  }

  findCommitteeRole(projectId, committeeId): CommitteeRole {
    const userRole = (this.employee?.userRoles || []).find(
      userRole => userRole.project.id === projectId
    );

    if (userRole) {
      return (userRole.committeeRoles || []).find(
        committeeRole => committeeRole.committeeId === committeeId
      );
    }
  }

  getMembershipProjects() {
    this.organization$
      .pipe(
        switchMap(organization =>
          this.membershipService.listMemberships(
            organization.id,
            this.companyService.isAllOrgsRollup(),
            'active'
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(
        memberships => {
          this.membershipProjects = memberships || [];
        },
        error => {
          // eslint-disable-next-line no-console
          console.error('Error fetching membership projects', error);
        }
      );
  }

  isProjectMembership(projectId: string): boolean {
    return this.membershipProjects.some(
      membership => membership.project?.id === projectId
    );
  }

  openProjectPage(projectId: string) {
    if (!projectId) {
      return;
    }

    const project = this.membershipProjects.find(
      membership => membership.project?.id === projectId
    )?.project;

    if (!project) {
      return;
    }

    if (project.projectType === 'Project Group') {
      this.router.navigate([
        `/${this.companyService.getCurrentCompanySlug()}/project/project-group/${projectId}/membership`,
      ]);
    } else {
      this.router.navigate([
        `/${this.companyService.getCurrentCompanySlug()}/project/${projectId}/membership`,
      ]);
    }

    this.onCloseModal();
  }

  openEditAccess() {
    setTimeout(() => {
      this.employeesMediatorService.openEditEmployee({
        employee: this.employee,
        accessSectionExpanded: true,
        isEmployeeFiltered: false,
      });
    }, 500);

    this.router.navigate([
      this.companyService.getCurrentCompanySlug() + '/users/access',
    ]);
    this.onCloseModal();
  }

  openEditKeyContacts() {
    setTimeout(() => {
      this.employeesMediatorService.openEditEmployee({
        employee: this.employee,
        keyContactsSectionExpanded: true,
        isEmployeeFiltered: false,
      });
    }, 500);

    this.router.navigate([
      this.companyService.getCurrentCompanySlug() + '/users/key-contacts',
    ]);

    this.onCloseModal();
  }

  openEditCommittees() {
    setTimeout(() => {
      this.employeesMediatorService.openEditEmployee({
        employee: this.employee,
        committeesSectionExpanded: true,
        isEmployeeFiltered: false,
      });
    }, 500);

    this.router.navigate([
      this.companyService.getCurrentCompanySlug() +
        '/users/board-and-committees',
    ]);

    this.onCloseModal();
  }

  onCloseModal() {
    this.activeOffcanvas.close();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private formatText(text) {
    return {
      text,
    };
  }
}

export interface UserProfileInput {
  employeeId: string;
  employeeEmail?: string;
  defaultEmployeeObject?: DefaultEmployeeObject;
  options?: UserProfileOptions;
  projectId?: string;
}

export interface UserProfileOptions {
  activity?: {
    collapsed: boolean;
    defaultFilter?:
      | 'Technical'
      | 'Events'
      | 'Training'
      | 'Meetings'
      | 'Subscriptions';
  };
  access?: {
    collapsed: boolean;
  };
  keyContacts?: {
    collapsed: boolean;
  };
  committees?: {
    collapsed: boolean;
  };
  contributor?: {
    collapsed: boolean;
  };
}
