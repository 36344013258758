// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const tnCCorporateVsIndividualEnrollmentQuery = gql`
  query tnCCorporateVsIndividualEnrollment(
    $organizationId: String!
    $isAllOrgs: Boolean
    $timeRange: String
    $projectId: String
  ) {
    getTnCCorporateVsIndividualEnrollment(
      organizationId: $organizationId
      isAllOrgs: $isAllOrgs
      timeRange: $timeRange
      projectId: $projectId
    ) {
      year
      month
      quarter
      spanDate
      instructionType
      enrollmentType
      coursesEnrrolledCount
    }
  }
`;
