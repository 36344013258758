// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const contributorLeaderboardQuery = gql`
  query contributorLeaderboard($input: ContributorLeaderboardInput) {
    contributorLeaderboard(input: $input) {
      code
      message
      contributorLeaderboard {
        contributorId
        displayName
        username
        logoUrl
        accountId
        contributionCount
        contributionPercentage
        isMyContributor
        userId
        accountName
      }
    }
  }
`;
