// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  EventEmitter,
  inject,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { downloadCSVFile } from '@lfx/core/helper';
import { Company } from '@lfx/core/models';
import {
  CompanyService,
  TrainingCertificationsService,
} from '@lfx/core/services';
import { Column } from '@lfx/shared/interfaces';
import {
  NgbActiveOffcanvas,
  NgbOffcanvasRef,
} from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, first, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'lfx-training-n-certificate-details',
  templateUrl: './training-n-certificate-details.component.html',
  styleUrls: ['./training-n-certificate-details.component.scss'],
})
export class TrainingNCertificateDetailsComponent implements OnInit {
  activeOffcanvas = inject(NgbActiveOffcanvas);
  public sectionTitle = '';
  public subTitle = '';
  searchInput = '';

  @Output() submitEmitter = new EventEmitter<string>();
  myCompany$: Observable<Company> = this.companyService.getMyCompany();
  public courseGroupId: string;
  public timeRange$: Observable<string>;
  public last12MonthsOnly = false;
  public projectId: string;

  columns: Column[] = null;

  data = [];
  isLoading = true;
  currentPage = 1;
  pageSize = 10;
  selectedTimeRange;
  private _page = new BehaviorSubject<number>(1);
  private page$ = this._page.asObservable();

  private _searchValue = new BehaviorSubject<string>('');
  private searchValue$ = this._searchValue.asObservable();

  private _refresh = new BehaviorSubject<void>(null);
  private refresh$ = this._refresh.asObservable();

  searchValue = '';

  listData$: Observable<
    {
      name: { text: any; subtext: any; url: any };
      enrollmentTs: { text: any; subtext: any; url: any };
      imageUrl: any;
      rank: { text: any; subtext: any; url: any };
    }[]
  >;

  offCanvasRef: NgbOffcanvasRef;

  dataCount$: Observable<number>;

  @ViewChild('nameColumn', { static: true })
  nameColumn: TemplateRef<any>;

  isAllOrgsRollup: boolean;

  constructor(
    private companyService: CompanyService,
    private trainingCertificationsService: TrainingCertificationsService
  ) {}

  onPageChange(event: number) {
    this.currentPage = event;
    this._page.next(event);
  }

  clearSearch() {
    this.searchValue = '';
    this.searchInputChange('');
  }

  searchInputChange(value: string): void {
    this.onPageChange(1);
    this._searchValue.next(value);
  }

  formatText(text, subtext = null, url = null) {
    return {
      text,
      subtext,
      url,
    };
  }

  tsToDate(dateString: string) {
    const date = new Date(+dateString);
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${month}/${day}/${year}`;
  }

  formatDate(dateString: string) {
    const formattedDate = this.tsToDate(dateString);

    return this.formatText(formattedDate);
  }

  formatDataToTable(tableData: any[]) {
    return tableData.map(event => ({
      name: this.formatText(event.name),
      accountName: this.formatText(event.accountName),
      enrollmentTs: this.formatDate(event.enrollmentTs),
      imageUrl: event.imageUrl,
      rank: this.formatText(event.rank),
      userId: event.userId,
    }));
  }

  ngOnInit(): void {
    this.columns = [
      {
        name: '',
        prop: 'rank',
      },
      {
        name: 'Name',
        prop: 'name',
        template: this.nameColumn,
      },
      {
        name: 'Enrollment Date',
        prop: 'enrollmentTs',
      },
    ];
    this.listData$ = combineLatest([
      this.myCompany$,
      this.timeRange$,
      this.page$,
      this.searchValue$.pipe(debounceTime(500)),
      this.refresh$,
    ]).pipe(
      tap(([, timeRange, ,]) => {
        this.isLoading = true;

        if (!this.selectedTimeRange) {
          this.selectedTimeRange = timeRange;
        }
      }),
      switchMap(([company, timeRange, page, searchName]) =>
        this.trainingCertificationsService
          .getTnCListDetails(
            company.id,
            this.companyService.isAllOrgsRollup(),
            this.selectedTimeRange || timeRange,
            this.courseGroupId,
            page,
            this.pageSize,
            searchName,
            this.last12MonthsOnly,
            this.projectId
          )
          .pipe(
            map(data =>
              this.formatDataToTable(
                data.map((item, index) => ({
                  name: item.userFullName,
                  enrollmentTs: item.enrollmentTs,
                  imageUrl: item.organizationLogo || item.userPhotoUrl,
                  rank: index + 1 + (page - 1) * this.pageSize,
                  accountName: item.accountName,
                  userId: item.userId,
                }))
              )
            )
          )
      ),
      tap(() => {
        this.isAllOrgsRollup = this.companyService.isAllOrgsRollup();
        this.isLoading = false;
      })
    );

    this.dataCount$ = combineLatest([
      this.myCompany$,
      this.timeRange$,
      this.searchValue$,
      this.refresh$,
    ]).pipe(
      switchMap(([company, timeRange, searchName]) =>
        this.trainingCertificationsService.getTnCListDetailsCount(
          company.id,
          this.companyService.isAllOrgsRollup(),
          this.selectedTimeRange || timeRange,
          this.courseGroupId,
          searchName,
          this.last12MonthsOnly,
          this.projectId
        )
      )
    );
  }

  selectDatePicker(dateRange) {
    this.selectedTimeRange = dateRange;
    this._refresh.next(null);
  }

  async exportData() {
    try {
      await combineLatest([
        this.myCompany$,
        this.timeRange$,
        this.searchValue$.pipe(debounceTime(500)),
      ])
        .pipe(
          first(),
          tap(() => {
            this.isLoading = true;
          }),
          switchMap(([company, timeRange, searchName]) =>
            this.trainingCertificationsService
              .getTnCListDetails(
                company.id,
                this.companyService.isAllOrgsRollup(),
                timeRange,
                this.courseGroupId,
                0,
                0,
                searchName,
                this.last12MonthsOnly,
                this.projectId
              )
              .pipe(
                map(data =>
                  data.map(item => ({
                    Name: item.userFullName,
                    'Enrollment Data': this.tsToDate(item.enrollmentTs),
                  }))
                )
              )
          ),
          tap(result => {
            downloadCSVFile(result, this.sectionTitle + 'Enrollment Data');
            this.isLoading = false;
          })
        )
        .toPromise();
    } catch (error) {
      // TODO show error message
    }
  }
}
