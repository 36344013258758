<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<!-- begin #page-container -->
<div
  id="page-container"
  class="page-container fade page-sidebar-fixed page-header-fixed show"
  [ngClass]="{
    'has-scroll': pageHasScroll,
    'page-sidebar-minified': pageSettings.pageSidebarMinified,
    'page-with-footer': pageSettings.pageWithFooter,
    'page-content-full-height': pageSettings.pageContentFullHeight,
    'page-without-sidebar': pageSettings.pageWithoutSidebar,
    'page-with-right-sidebar': pageSettings.pageSidebarRight,
    'page-with-two-sidebar': pageSettings.pageSidebarTwo,
    'page-with-wide-sidebar': pageSettings.pageSidebarWide,
    'page-right-sidebar-collapsed': pageSettings.pageSidebarRightCollapsed,
    'page-with-light-sidebar': pageSettings.pageSidebarLight,
    'page-with-top-menu': pageSettings.pageTopMenu,
    'page-sidebar-toggled': pageSettings.pageMobileSidebarToggled,
    'page-right-sidebar-toggled': pageSettings.pageMobileRightSidebarToggled,
    'p-0': pageSettings.pageEmpty
  }"
>
  <lfx-top-menu
    *ngIf="pageSettings.pageTopMenu && !pageSettings.pageEmpty"
  ></lfx-top-menu>

<!--  <lfx-local-header
    (toggleSidebarRightCollapsed)="onToggleSidebarRight($event)"
    (toggleMobileSidebar)="onToggleMobileSidebar($event)"
    (toggleMobileRightSidebar)="onToggleMobileRightSidebar($event)"
    (logout)="logout($event)"
    [pageSidebarTwo]="pageSettings.pageSidebarTwo"
    [profile]="authService.userProfile$ | async"
    [notificationsLength]="notificationsLength$ | async"
    [isLoggedIn]="authService.loggedIn"
    *ngIf="!pageSettings.pageEmpty"
  ></lfx-local-header>-->

  <lfx-sidebar
    (hideMobileSidebar)="onHideMobileSidebar($event)"
    (sidebarCollapsed)="sidebarCollapsed($event)"
    [profile]="authService.userProfile$ | async"
    *ngIf="!pageSettings.pageWithoutSidebar && !pageSettings.pageEmpty && authService.loggedIn"
    [pageSidebarTransparent]="pageSettings.pageSidebarTransparent"
    [pageSidebarMinified]="pageSettings.pageSidebarMinified"
    [isLoggedIn]="authService.loggedIn"
    [mobileExpandedClicked]="mobileExpandedClicked"
    (showSidebarToggleButton)="showSidebarToggleButton = $event"
    ></lfx-sidebar>
    <!-- [isCollapsed]="authService.loggedIn && (isAdmin$ | async)" -->

  <!-- Sidebar toggle button -->
  <div
     *ngIf="mobileMode && (showSidebarToggleButton || !companyService.showSelectOrgView)" class="collapse-expand-btn"
     (click)="mobileExpandedClicked = !mobileExpandedClicked">
    <ng-container>
      <i class="fas fa-chevron-double-right"></i>
    </ng-container>
  </div>

  <!-- commenting this out for now, sidebar-right might be needed in future -->
<!--
  <lfx-sidebar-right
    (hideMobileRightSidebar)="onHideMobileRightSidebar($event)"
    *ngIf="pageSettings.pageSidebarTwo && !pageSettings.pageEmpty"
    iframeSrc="{{ helpUrl }}"
  ></lfx-sidebar-right> -->
  <ng-container *ngxPermissionsOnly="['feature_help']">
    <lfx-help-menu
      (getPageHelp)="pageSettings.pageHelpMenu = true"
      (createSupportTicket)="helpUrl = $event; pageSettings.pageHelpMenu = null;pageSettings.pageHelpSupportFrame = true"
      *ngIf="pageSettings.pageHelpMenu">

    </lfx-help-menu>
    <lfx-help-support-frame
      *ngIf="pageSettings.pageHelpMenu && !pageSettings.pageEmpty"
      [iframeSrc]="helpUrl"
    ></lfx-help-support-frame>
  </ng-container>

  <div
    id="content"
    class="content"
    [ngClass]="{
      'content-full-width': pageSettings.pageContentFullWidth,
      'content-inverse-mode': pageSettings.pageContentInverseMode,
      'content-grey-bg': pageSettings.pageContentGreyBackground,
      'p-0 m-0': pageSettings.pageEmpty,
      'mobile-content': mobileMode,
      'org-select-padding': companyService.showSelectOrgView
    }"
  >
    <router-outlet (activate)="setUserInLFxHeader()"></router-outlet>
    <!-- <lfx-top-btn></lfx-top-btn> -->
    <lfx-footer *ngIf="pageSettings.pageWithFooter" ></lfx-footer>
  </div>

  <lfx-toast aria-live="polite" aria-atomic="true"></lfx-toast>
</div>
<!-- end #page-container -->
