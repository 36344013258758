// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { environment as defaultEnvironment } from './environment.defaults';

const pageQueryParams = window.location.search || '';

export const environment = {
  ...defaultEnvironment,
  production: true,
  log: true,
  auth0: {
    client_id: 'aHbAnFCTLNIZ4EUtBghDvn6eTAIrkONN',
    domain: 'linuxfoundation-staging.auth0.com',
    audience: 'https://linuxfoundation-staging.auth0.com/api/v2/',
    redirect_uri: `${window.location.origin}/auth${pageQueryParams}`,
    responseType: 'token id_token',
  },
  auth0Social: {
    domain: 'linuxfoundation-staging.auth0.com',
    audience: 'https://linuxfoundation-staging.auth0.com/api/v2/',
    client_id: 'uOJN7anByR00W52aFCxTSdE4K1F65uaT',
    redirect_uri: `${window.location.origin}/user/account-settings`,
    responseType: 'token id_token',
    scope: 'openid profile email',
  },
  lfxHeaderUrl:
    'https://cdn.staging.platform.linuxfoundation.org/lfx-header-v2.js',
  apiUrl: 'https://lf-backend.staging.platform.linuxfoundation.org',
  graphqlUrl: 'https://lf-backend.staging.platform.linuxfoundation.org/graphql',
  ldClientKey: '65030d5ac571221241515afa',
  joinFoundationUrl:
    'https://joinnow.staging.platform.linuxfoundation.org/?app=lfx&project=',
  cclaUrl: 'https://lfx-cla.s3.amazonaws.com/ccla.pdf',
  profileManagementUrl: 'https://myprofile.staging.linuxfoundation.org/',
  enrollmentCDN:
    'https://joinnow.staging.platform.linuxfoundation.org/wc/joinnow-form.min.js',
  lfxAcsUICDN: 'https://lfx-acs-ui.staging.platform.linuxfoundation.org',
  v3Url: 'https://lfx.v3.staging.platform.linuxfoundation.org',
  v2Url: 'https://lfx.v2.staging.platform.linuxfoundation.org',
  intercomId: 'mxl90k6y',
};
