<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="user-types-dropdown">
  <ng-select
    [clearable]="false"
    [searchable]="false"
    [(ngModel)]="selectedItem"
    (change)="onSelected($event)"
    class="daterange-dropdown"
    [class.small-style]="style === 'small'"
  >
    <ng-option [value]="item.id" *ngFor="let item of listItems">
      {{ item.name }}
    </ng-option>
  </ng-select>
</div>
