// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  tnCCertifiedEmployeesLeaderboardCountQuery,
  tnCCertifiedEmployeesLeaderboardQuery,
  tnCCorporateVsIndividualEnrollmentQuery,
  tnCGrowthQuery,
  tnCListDetailsCountQuery,
  tnCListQuery,
  tnCOverviewQuery,
  tnCTrainedEmployeesLeaderboardCountQuery,
  tnCTrainedEmployeesLeaderboardQuery,
  trainingAndCertificationSummaryQuery,
} from '../queries';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  TnCCertifiedEmployeesLeaderboard,
  TnCCertifiedEmployeesLeaderboardCount,
  TnCCorporateVsIndividualEnrollment,
  TnCGrowth,
  TnCList,
  TnCListDetails,
  TnCListDetailsCount,
  TnCOverview,
  TnCTrainedEmployeesLeaderboard,
  TnCTrainedEmployeesLeaderboardCount,
  TrainingAndCertificationSummary,
} from '@models';
import { tnCListDetailsQuery } from '../queries/tnc/list-details';

@Injectable({
  providedIn: 'root',
})
export class TrainingCertificationsServiceGql {
  constructor(private apollo: Apollo) {}

  getTraningAndCertificationSummary(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<TrainingAndCertificationSummary> {
    return this.apollo
      .query<{
        trainingAndCertificationSummary: TrainingAndCertificationSummary;
      }>({
        query: trainingAndCertificationSummaryQuery,
        variables: {
          organizationId,
          dateRange,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .pipe(
        map(res => res.data.trainingAndCertificationSummary),
        catchError(() => of(null))
      );
  }

  getTnCOverview(
    organizationId: string,
    isAllOrgs: boolean,
    timeRange: string,
    projectId: string,
    noCache = false
  ): Observable<TnCOverview> {
    return this.apollo
      .query<{
        getTnCOverview: TnCOverview;
      }>({
        query: tnCOverviewQuery,
        variables: {
          organizationId,
          isAllOrgs,
          timeRange,
          projectId,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .pipe(
        map(res => res.data.getTnCOverview[0]),
        catchError(() => of(null))
      );
  }

  getTnCList(
    organizationId: string,
    isAllOrgs: boolean,
    timeRange: string,
    filter: string,
    projectId: string,
    noCache = false
  ): Observable<TnCList[]> {
    return this.apollo
      .query<{
        getTnCList: TnCList;
      }>({
        query: tnCListQuery,
        variables: {
          organizationId,
          isAllOrgs,
          timeRange,
          filter,
          projectId,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .pipe(
        map(res => res.data.getTnCList),
        catchError(() => of(null))
      );
  }

  getTnCListDetails(
    organizationId: string,
    isAllOrgs: boolean,
    timeRange: string,
    courseGroupId: string,
    page: number,
    limit: number,
    searchName: string,
    last12MonthsOnly: boolean,
    projectId: string,
    noCache = false
  ): Observable<TnCListDetails[]> {
    return this.apollo
      .query<{
        getTnCListDetails: TnCListDetails;
      }>({
        query: tnCListDetailsQuery,
        variables: {
          organizationId,
          isAllOrgs,
          timeRange,
          courseGroupId,
          page,
          limit,
          searchName,
          last12MonthsOnly,
          projectId,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .pipe(
        map(res => res.data.getTnCListDetails),
        catchError(() => of(null))
      );
  }

  getTnCListDetailsCount(
    organizationId: string,
    isAllOrgs: boolean,
    timeRange: string,
    courseGroupId: string,
    searchName: string,
    last12MonthsOnly: boolean,
    projectId: string,
    noCache = false
  ): Observable<number> {
    return this.apollo
      .query<{
        getTnCListDetailsCount: TnCListDetailsCount;
      }>({
        query: tnCListDetailsCountQuery,
        variables: {
          organizationId,
          isAllOrgs,
          timeRange,
          courseGroupId,
          searchName,
          last12MonthsOnly,
          projectId,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .pipe(
        map(res => res.data.getTnCListDetailsCount[0].count),
        catchError(() => of(null))
      );
  }

  getTnCTrainedEmployeesLeaderboard(
    organizationId: string,
    isAllOrgs: boolean,
    timeRange: string,
    page: number,
    limit: number,
    projectId: string,
    noCache = false
  ): Observable<TnCTrainedEmployeesLeaderboard[]> {
    return this.apollo
      .query<{
        getTnCTrainedEmployeesLeaderboard: TnCTrainedEmployeesLeaderboard;
      }>({
        query: tnCTrainedEmployeesLeaderboardQuery,
        variables: {
          organizationId,
          isAllOrgs,
          timeRange,
          page,
          limit,
          projectId,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .pipe(
        map(res => res.data.getTnCTrainedEmployeesLeaderboard),
        catchError(() => of(null))
      );
  }

  getTnCTrainedEmployeesLeaderboardCount(
    organizationId: string,
    isAllOrgs: boolean,
    timeRange: string,
    projectId: string,
    noCache = false
  ): Observable<number> {
    return this.apollo
      .query<{
        getTnCTrainedEmployeesLeaderboardCount: TnCTrainedEmployeesLeaderboardCount;
      }>({
        query: tnCTrainedEmployeesLeaderboardCountQuery,
        variables: {
          organizationId,
          isAllOrgs,
          timeRange,
          projectId,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .pipe(
        map(res => res.data.getTnCTrainedEmployeesLeaderboardCount[0].count),
        catchError(() => of(null))
      );
  }

  getTnCCertifiedEmployeesLeaderboard(
    organizationId: string,
    isAllOrgs: boolean,
    timeRange: string,
    page: number,
    limit: number,
    projectId: string,
    noCache = false
  ): Observable<TnCCertifiedEmployeesLeaderboard[]> {
    return this.apollo
      .query<{
        getTnCCertifiedEmployeesLeaderboard: TnCCertifiedEmployeesLeaderboard;
      }>({
        query: tnCCertifiedEmployeesLeaderboardQuery,
        variables: {
          organizationId,
          isAllOrgs,
          timeRange,
          page,
          limit,
          projectId,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .pipe(
        map(res => res.data.getTnCCertifiedEmployeesLeaderboard),
        catchError(() => of(null))
      );
  }

  getTnCCertifiedEmployeesLeaderboardCount(
    organizationId: string,
    isAllOrgs: boolean,
    timeRange: string,
    projectId: string,
    noCache = false
  ): Observable<number> {
    return this.apollo
      .query<{
        getTnCCertifiedEmployeesLeaderboardCount: TnCCertifiedEmployeesLeaderboardCount;
      }>({
        query: tnCCertifiedEmployeesLeaderboardCountQuery,
        variables: {
          organizationId,
          isAllOrgs,
          timeRange,
          projectId,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .pipe(
        map(res => res.data.getTnCCertifiedEmployeesLeaderboardCount[0].count),
        catchError(() => of(null))
      );
  }

  getTnCGrowth(
    organizationId: string,
    isAllOrgs: boolean,
    timeRange: string,
    projectId: string,
    noCache = false
  ): Observable<TnCGrowth[]> {
    return this.apollo
      .query<{
        getTnCGrowth: TnCGrowth;
      }>({
        query: tnCGrowthQuery,
        variables: {
          organizationId,
          isAllOrgs,
          timeRange,
          projectId,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .pipe(
        map(res => res.data.getTnCGrowth),
        catchError(() => of(null))
      );
  }

  getTnCCorporateVsIndividualEnrollment(
    organizationId: string,
    isAllOrgs: boolean,
    timeRange: string,
    projectId: string,
    noCache = false
  ): Observable<TnCCorporateVsIndividualEnrollment[]> {
    return this.apollo
      .query<{
        getTnCCorporateVsIndividualEnrollment: TnCCorporateVsIndividualEnrollment;
      }>({
        query: tnCCorporateVsIndividualEnrollmentQuery,
        variables: {
          organizationId,
          isAllOrgs,
          timeRange,
          projectId,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .pipe(
        map(res => res.data.getTnCCorporateVsIndividualEnrollment),
        catchError(() => of(null))
      );
  }
}
