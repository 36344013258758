// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import {
  TnCOverview,
  TnCTrainedEmployeesLeaderboard,
  TnCCertifiedEmployeesLeaderboard,
  TrainingAndCertificationSummary,
  TnCGrowth,
  TnCCorporateVsIndividualEnrollment,
  TnCListDetails,
  TnCListResponse,
} from '@models';
import { Observable } from 'rxjs';
import { TrainingCertificationsServiceGql } from '../gql';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class TrainingCertificationsService {
  constructor(
    private httpClient: HttpClient,
    private trainingCertificationsServiceGql: TrainingCertificationsServiceGql
  ) {}

  getTraningAndCertificationSummary(
    organizationId: string,
    dateRange: string
  ): Observable<TrainingAndCertificationSummary> {
    return this.trainingCertificationsServiceGql.getTraningAndCertificationSummary(
      organizationId,
      dateRange
    );
  }

  getTnCOverview(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    projectId: string
  ): Observable<TnCOverview> {
    return this.trainingCertificationsServiceGql.getTnCOverview(
      organizationId,
      isAllOrgs,
      dateTime,
      projectId
    );
  }

  getTnCGrowth(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    projectId: string
  ): Observable<TnCGrowth[]> {
    return this.trainingCertificationsServiceGql.getTnCGrowth(
      organizationId,
      isAllOrgs,
      dateTime,
      projectId
    );
  }

  getTnCCorporateVsIndividualEnrollment(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    projectId: string
  ): Observable<TnCCorporateVsIndividualEnrollment[]> {
    return this.trainingCertificationsServiceGql.getTnCCorporateVsIndividualEnrollment(
      organizationId,
      isAllOrgs,
      dateTime,
      projectId
    );
  }

  getTnCList(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    filter?: string,
    projectId?: string,
    allAvailable?: boolean
  ): Observable<TnCListResponse> {
    const route = `${environment.apiUrl}/training-and-certifications/list`;

    let params = new HttpParams()
      .set('organizationId', organizationId)
      .set('isAllOrgs', isAllOrgs.toString())
      .set('timeRange', dateTime)
      .set('allAvailable', allAvailable?.toString() || 'false');

    if (filter) {
      params = params.set('filter', filter);
    }

    if (projectId) {
      params = params.set('projectId', projectId);
    }

    return this.httpClient.get<TnCListResponse>(route, {
      params,
    });
  }

  getTnCListDetails(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    courseGroupId: string,
    page: number,
    limit: number,
    searchName: string,
    last12MonthsOnly: boolean,
    projectId: string
  ): Observable<TnCListDetails[]> {
    return this.trainingCertificationsServiceGql.getTnCListDetails(
      organizationId,
      isAllOrgs,
      dateTime,
      courseGroupId,
      page,
      limit,
      searchName,
      last12MonthsOnly,
      projectId
    );
  }

  getTnCListDetailsCount(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    courseGroupId: string,
    searchName: string,
    last12MonthsOnly: boolean,
    projectId: string
  ): Observable<number> {
    return this.trainingCertificationsServiceGql.getTnCListDetailsCount(
      organizationId,
      isAllOrgs,
      dateTime,
      courseGroupId,
      searchName,
      last12MonthsOnly,
      projectId
    );
  }

  getTnCTrainedEmployeesLeaderboard(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    page: number,
    limit: number,
    projectId: string
  ): Observable<TnCTrainedEmployeesLeaderboard[]> {
    return this.trainingCertificationsServiceGql.getTnCTrainedEmployeesLeaderboard(
      organizationId,
      isAllOrgs,
      dateTime,
      page,
      limit,
      projectId
    );
  }

  getTnCTrainedEmployeesLeaderboardCount(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    projectId: string
  ): Observable<number> {
    return this.trainingCertificationsServiceGql.getTnCTrainedEmployeesLeaderboardCount(
      organizationId,
      isAllOrgs,
      dateTime,
      projectId
    );
  }

  getTnCCertifiedEmployeesLeaderboard(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    page: number,
    limit: number,
    projectId: string
  ): Observable<TnCCertifiedEmployeesLeaderboard[]> {
    return this.trainingCertificationsServiceGql.getTnCCertifiedEmployeesLeaderboard(
      organizationId,
      isAllOrgs,
      dateTime,
      page,
      limit,
      projectId
    );
  }

  getTnCCertifiedEmployeesLeaderboardCount(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    projectId: string
  ): Observable<number> {
    return this.trainingCertificationsServiceGql.getTnCCertifiedEmployeesLeaderboardCount(
      organizationId,
      isAllOrgs,
      dateTime,
      projectId
    );
  }
}
