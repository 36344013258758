// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { BaseListOffcanvasDirective } from '../base-list-offcanvas/base-list-offcanvas.directive';
import { MembershipService } from '@lfx/core/services';
import { Column, DataRowV2 } from '@lfx/shared/interfaces';
import { CompanyService } from '@lfx/core/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { MembershipProject } from '@lfx/core/models/membership-detail';

@Component({
  selector: 'lfx-membership-projects-offcanvas',
  templateUrl: './membership-projects-offcanvas.component.html',
  styleUrls: [
    '../base-list-offcanvas/base-list-offcanvas.component.scss',
    './membership-projects-offcanvas.component.scss',
  ],
})
export class MembershipProjectsOffcanvasComponent
  extends BaseListOffcanvasDirective
  implements OnInit
{
  @Input() foundationInfo: FoundationInfo;
  @Output() columnTextClickAction = new EventEmitter<{
    row: DataRowV2;
    column: Column;
  }>();

  @ViewChild('checkMarkTemplate', { static: true })
  checkMarkTemplate: TemplateRef<any>;

  title = 'Your Project Involvement';
  subtitle = 'Last 12 Months';

  constructor(
    activeOffcanvas: NgbActiveOffcanvas,
    offcanvasService: NgbOffcanvas,
    companyService: CompanyService,
    spinner: NgxSpinnerService,
    private membershipService: MembershipService
  ) {
    super(activeOffcanvas, offcanvasService, companyService, spinner);
  }

  ngOnInit() {
    this.columns = this.getColumns();
    this.fetchData();
  }

  protected getColumns(): Column[] {
    return [
      {
        name: '',
        prop: 'index',
        style: {
          textAlign: 'center',
        },
      },
      {
        name: 'Name',
        prop: 'name',
        sortable: true,
        sortDir: 'asc',
        clickableText: false,
        columnWidth: '60%',
      },
      {
        name: '',
        prop: 'meetingAttendance',
        headerFaIcon: {
          icon: 'fal fa-calendar-alt',
          hoverText: 'Meeting Attendance',
          svgAsset: 'assets/img/fa-calendar-days.svg',
        },
        template: this.checkMarkTemplate,
      },
      {
        name: '',
        prop: 'eventAttendance',
        headerFaIcon: {
          icon: 'fal fa-calendar-day',
          hoverText: 'Event Attendance',
          svgAsset: 'assets/img/fa-calendar-day.svg',
        },
        template: this.checkMarkTemplate,
      },
      {
        name: '',
        prop: 'codeContribution',
        headerFaIcon: {
          icon: 'fal fa-code',
          hoverText: 'Code Contributions',
          svgAsset: 'assets/img/fa-code.svg',
        },
        template: this.checkMarkTemplate,
      },
      {
        name: '',
        prop: 'trainingCertification',
        headerFaIcon: {
          icon: 'fal fa-file-certificate',
          hoverText: 'Training & Certifications',
          svgAsset: 'assets/img/fa-file-certificate.svg',
        },
        template: this.checkMarkTemplate,
      },
      {
        name: '',
        prop: 'mailingList',
        headerFaIcon: {
          icon: 'fal fa-mail-bulk',
          hoverText: 'Mailing Lists',
          svgAsset: 'assets/img/fa-envelopes-bulk.svg',
        },
        template: this.checkMarkTemplate,
      },
    ];
  }

  protected fetchData() {
    if (!this.foundationInfo?.foundationId) {
      return;
    }

    this.setIsLoading(true);
    this.membershipService
      .getMembershipProjects(
        this.foundationInfo.accountId,
        this.foundationInfo.foundationId
      )
      .subscribe({
        next: projects => {
          this.data = this.transformProjectsData(projects);
          this.searchData = this.data;
          this.onPageChange(1);
          this.setIsLoading(false);
        },
        error: () => {
          this.setIsLoading(false);
          this.emptyDataMessage =
            'Failed to load membership projects. Please try again.';
        },
      });
  }

  protected getCSVData() {
    return this.data.map(row => {
      const csvRow: Record<string, string> = {};

      const columnsForCSV = this.columns.filter(
        col => col.name || col.headerFaIcon
      );

      columnsForCSV.forEach(column => {
        const headerName = column.headerFaIcon?.hoverText || column.name;

        if (!headerName) {
          return;
        }

        if (column.template === this.checkMarkTemplate) {
          let value = 'N/A';

          if (row[column.prop].text === 'TRUE') {
            value = 'Yes';
          } else if (row[column.prop].text === '-') {
            value = 'No';
          }

          csvRow[headerName] = value;
        } else {
          csvRow[headerName] = row[column.prop].text;
        }
      });

      return csvRow;
    });
  }

  protected getListType(): string {
    return `${this.title}-${this.subtitle}-${this.foundationInfo?.foundationName}`;
  }

  private transformProjectsData(projects: MembershipProject[]): DataRowV2[] {
    return [...projects]
      .sort((a, b) => {
        const aValue = a?.projectName || '';
        const bValue = b?.projectName || '';

        return String(aValue).localeCompare(String(bValue));
      })
      .map((project, index) => ({
        index: this.formatText(`${index + 1}`),
        name: this.formatText(project.projectName, null, null, null, {
          projectId: project.projectId,
        }),
        meetingAttendance: this.formatText(project.meetingAttendance || 'NA'),
        eventAttendance: this.formatText(project.eventRegistration || 'NA'),
        codeContribution: this.formatText(project.contribution || 'NA'),
        trainingCertification: this.formatText(project.tnc || 'NA'),
        mailingList: this.formatText(project.mailingList || 'NA'),
      }));
  }
}

export interface FoundationInfo {
  accountId: string;
  foundationId: string;
  foundationName: string;
  count: number;
  totalProjectCount: number;
}
