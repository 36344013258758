// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const tnCOverviewQuery = gql`
  query tnCOverview(
    $organizationId: String!
    $isAllOrgs: Boolean
    $timeRange: String
    $projectId: String
  ) {
    getTnCOverview(
      organizationId: $organizationId
      isAllOrgs: $isAllOrgs
      timeRange: $timeRange
      projectId: $projectId
    ) {
      trainingCourses
      individualsEnrolledInTraining
      certificationTypes
      individualsPassedCertification
    }
  }
`;
