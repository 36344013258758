// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const listMembershipsSnowflakeQuery = gql`
  query listMembershipsSnowflake(
    $accountId: ID!
    $isAllOrgs: Boolean
    $status: String
  ) {
    listMembershipsSnowflake(
      accountId: $accountId
      isAllOrgs: $isAllOrgs
      status: $status
    ) {
      product {
        name
      }
      projectName
      accountId
      accountName
      endDate
      projectId
      installDate
      firstMembershipStartedAt
      project {
        id
        name
        logoUrl
        projectType
      }
      projectCount
    }
  }
`;
