<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->
<div class="offcanvas-container">
  <button class="close-button-box" (click)="activeOffcanvas.close()" aria-label="Close" type="button">
    <i class="fal fa-times"></i>
  </button>
  <div class="main-content-box">
    <div class="main-content">
      <ng-container *ngIf="foundationInfo">
        <!-- Foundation Header -->
        <div class="list-cell">
          <div class="list-cell-name">{{ foundationInfo.foundationName }}</div>
          <div class="list-cell-subtext">
            There are {{ foundationInfo.totalProjectCount }} total projects in this foundation. Your organization is involved in {{ foundationInfo.count }} of them through either attending meetings, attending events, contributing code, enrolling in training/certifications, or subscribing to mailing lists.
          </div>
        </div>

        <!-- Title and Actions -->
        <div class="title-header">
          <div class="title-subtitle">
            <div class="title">{{ title }} ({{ foundationInfo.count | number }})</div>
            <div class="subtitle">{{ subtitle }}</div>
          </div>
          <div class="top-right">
            <!-- Search -->
            <div class="lf-search-container">
              <span class="far fa-search cursor-pointer"></span>
              <img
                src="assets/img/svgs/normal_clear_u481.svg"
                class="clear-img"
                (click)="clearSearch()"
                *ngIf="searchValue !== ''"
              />
              <input
                type="text"
                class="form-control"
                placeholder="Search"
                [(ngModel)]="searchValue"
                (ngModelChange)="onSearchChange($event)"
              />
            </div>
            <!-- Download -->
            <div class="lf-btn-download-container">
              <i
                class="fal fa-arrow-to-bottom"
                placement="bottom"
                tooltipClass="lf-box-tooltip"
                ngbTooltip="Download CSV"
                (click)="downloadCsv()"
              ></i>
            </div>
          </div>
        </div>

        <!-- Table -->
        <div class="table-container position-relative">
          <ngx-spinner
            type="ball-clip-rotate"
            [fullScreen]="false"
            [name]="spinnerName"
            color="rgba(0,0,0,0.5)"
            bdColor="rgba(255,255,255,0.5)"
            size="default"
          ></ngx-spinner>

          <lfx-new-table
            [columns]="columns"
            [data]="paginatedData"
            [version]="2"
            class="mb-4"
            (columnSortEmmitter)="sortDataColumn($event)"
            [emptyDataMessage]="isLoading ? 'Loading...' : emptyDataMessage"
            (columnTextClickAction)="columnTextClickAction.emit($event)"
          >
          </lfx-new-table>
        </div>

        <!-- Pagination -->
        <lfx-ngb-pagination-wrapper
          *ngIf="paginatedData?.length"
          [pageSize]="pageSize"
          [page]="page"
          [collectionSize]="totalSize"
          (pageChange)="onPageChange($event)"
          [version]="2"
        ></lfx-ngb-pagination-wrapper>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #checkMarkTemplate let-row="row" let-column="column">
  <div class="check-mark-container">
    <i *ngIf="row[column.prop].text === 'TRUE'" class="fal fa-check yes-icon"></i>
    <i *ngIf="row[column.prop].text === '-'" class="fal fa-ban no-icon"></i>
    <i *ngIf="row[column.prop].text === 'NA'" class="fal fa-horizontal-rule na-icon"></i>
  </div>
</ng-template>

