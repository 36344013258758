// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'lfx-date-range-dropdown',
  templateUrl: './date-range-dropdown.component.html',
  styleUrls: ['./date-range-dropdown.component.scss'],
})
export class DateRangeDropdownComponent implements OnInit {
  listItems = [
    { id: 'ytd', name: 'Year to Date' },
    { id: 'last_12mo', name: 'Last 12 Months' },
    { id: 'last_year', name: 'Previous Year' },
    { id: 'alltime', name: 'All Time' },
  ];

  @Output() selectDateRange = new EventEmitter<string>();
  @Input() selectedItem = 'last_12mo';
  @Input() style = 'large'; // large || small

  constructor() {}

  ngOnInit() {}

  onSelected(item: string) {
    this.selectedItem = item;
    this.selectDateRange.emit(item);
  }
}
