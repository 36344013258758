// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const getMemberAccountQuery = gql`
  query getMemberAccount($accountId: ID!) {
    getMemberAccount(accountId: $accountId) {
      accountCompanyType
      accountCreatedAt
      accountCreatedBy
      accountDescription
      accountDomainAlias
      accountId
      accountIndustry
      accountIsMember
      accountLastEmployeeSizeVerifiedDate
      accountLastModifiedAt
      accountLastModifiedBy
      accountLink
      accountLogoUrl
      accountName
      accountNumberOfEmployees
      accountNumberOfEmployeesSizeRange
      accountPhone
      accountPrimaryDomain
      accountSector
      accountSource
      accountStatus
      accountType
      accountCrunchBaseUrl
      accountsRelated {
        id
        isMember
        logoUrl
        name
        parentId
        slug
        type
        crunchBaseUrl
        description
        lastModifiedAt
        link
      }
    }
  }
`;
