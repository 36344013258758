// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const tnCListQuery = gql`
  query tnCList(
    $organizationId: String!
    $isAllOrgs: Boolean
    $timeRange: String
    $filter: String
    $projectId: String
  ) {
    getTnCList(
      organizationId: $organizationId
      isAllOrgs: $isAllOrgs
      timeRange: $timeRange
      filter: $filter
      projectId: $projectId
    ) {
      courseName
      enrollmentsCount
      productType
      courseGroupId
      courseLink
    }
  }
`;
