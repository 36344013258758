// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  updatePaginatedData<T>(data: T[], page: number, pageSize: number): T[] {
    if (!data?.length) {
      return [];
    }
    const startIndex = Math.max(0, (page - 1) * pageSize);
    const endIndex = Math.min(page * pageSize, data.length);

    return data.slice(startIndex, endIndex);
  }
}
