<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<!-- begin #sidebar -->
<div class="sidebar-overlay" [ngClass]="{ isCollapsed: isCollapsed }" (click)="setCollapse(true)"></div>
<div
  id="sidebar"
  class="sidebar"
  *ngIf="
    !myCompany?.accountsRelated?.length || !companyService.showSelectOrgView
  "
  (mouseenter)="setCollapse(false)"
  (mouseleave)="setCollapse(true)"
  [ngClass]="{
    'sidebar-transparent': this.pageSidebarTransparent,
    isCollapsed: isCollapsed,
    'mobile-view': mobileMode
  }"
>
  <!-- begin sidebar scrollbar -->
  <div
    class="height-full"
    #sidebarScrollbar
    *ngIf="desktopMode"
    (scroll)="onScroll($event)"
  >
    <ng-container *ngTemplateOutlet="sidebarNav"></ng-container>
  </div>
  <div class="mobile-scroll" *ngIf="mobileMode" (scroll)="onScroll($event)">
    <ng-container *ngTemplateOutlet="sidebarNav"></ng-container>
  </div>
  <!-- end sidebar scrollbar -->
  <ng-template #sidebarNav>
    <ngx-spinner
      type="ball-clip-rotate"
      bdColor="rgba(242,242,242, 0.5)"
      color="rgba(0,0,0,0.2)"
      size="large"
    ></ngx-spinner>
    <!-- Company Logo and Title -->
    <ng-container *ngIf="myCompany">
      <div class="company-section-primary">
        <div class="logo-container">
          <div *ngIf="myCompany.logoUrl">
            <img
              class="small-company-image"
              [src]="myCompany.logoUrl"
              [title]="myCompany.name"
              (error)="myCompany.logoUrl = null"
            />
            <div class="rollup-icon" *ngIf="isAllOrgsRollup">
              <i class="fas fa-sitemap"></i>
            </div>
          </div>
          <!-- <img
            *ngIf="!myCompany.logoUrl && isCollapsed"
            class="small-company-image rounded-full"
            [src]="defaultImage"
            [title]="myCompany.name"
          /> -->
          <ng-container
            *ngLet="isAdminOrStaffUser$ | async as isAdminOrStaffUser"
          >
            <div
              *ngIf="!myCompany.logoUrl"
              class="no-logo"
              [ngClass]="{ 'cur-pointer': isAdminOrStaffUser }"
              (click)="isAdminOrStaffUser && navigateToEditOrg()"
            >
              {{ myCompany.name[0] }}
              <div class="pen-container" *ngIf="isAdminOrStaffUser && !isCollapsed">
                <i class="far fa-pen fa-xs"></i>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="!isCollapsed">
          <div
            *ngIf="
              myCompany.name === individualNoAccount;
              else nonIndividualNoAccount
            "
            class="company-title"
          >
            {{ currentContext | titlecase }}
          </div>
          <ng-template #nonIndividualNoAccount>
            <div class="company-title">{{ myCompany.name }}</div>
          </ng-template>
          <div class="horizontal-line"></div>
        </ng-container>
      </div>
    </ng-container>

    <div class="conglomerate-section" *ngIf="isConglomerateOrg">
      <a (click)="navigateTohome()" *ngIf="!isCollapsed">Change Organization View</a>
    </div>

    <!-- Custom Section-->
    <ul #fixedInfoBox class="nav fixed-infobox">
      <li
        class="nav-company-details"
        [ngClass]="{ active: navProfileState == 'expand' }"
        *ngIf="isLoggedIn"
      >
        <div
          [ngSwitch]="currentContext"
          [class]="'company-section-' + (currentContext || 'default')"
        >
          <ng-template [ngSwitchCase]="userContextEnum.Staff">
            <ng-container *ngIf="!isCollapsed">
              <div class="px-3 search-container">
                <ng-select
                  #orgSearch
                  class="custom search-org"
                  bindLabel="name"
                  [searchable]="true"
                  [addTag]="false"
                  [closeOnSelect]="true"
                  [clearSearchOnAdd]="true"
                  [clearable]="true"
                  [placeholder]="'Search Organizations...'"
                  [loading]="searchLoading"
                  [loadingText]="'Loading...'"
                  [notFoundText]="'Not Found!'"
                  (search)="searchOrg($event)"
                  (clear)="orgSearchText = null"
                  (change)="setOrganization($event, orgSearch)"
                >
                  <ng-container *ngFor="let parent of searchResult$ | async">
                    <ng-container *ngIf="showOrgSearchMenu && orgSearchText">
                      <ng-option [value]="parent">
                        <div class="org-item">{{ parent.name }}</div>
                      </ng-option>
                      <ng-container *ngIf="parent.children">
                        <ng-option
                          *ngFor="let child of parent.children"
                          [value]="child"
                        >
                          <div class="org-item child-org-item">
                            <sup class="child-arrow">L</sup
                            >{{ ' ' + child.name }}
                          </div>
                        </ng-option>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-select>
              </div>
            </ng-container>
            <ng-container *ngIf="isCollapsed">
              <div class="small-search-icon">
                <i class="fal fa-search"></i>
              </div>
            </ng-container>
            <div class="horizontal-line"></div>
          </ng-template>
          <ng-template [ngSwitchCase]="userContextEnum.Admin">
            <img
              class="context-image"
              src="https://lfx-dev-mocks.s3.amazonaws.com/admin.jpg"
            />
            <div class="context-name">
              {{ profile.firstName }} {{ profile.lastName }}
            </div>
            <div class="context-title">{{ currentContext | titlecase }}</div>
          </ng-template>
          <ng-template ngSwitchDefault>

          </ng-template>
        </div>
      </li>
      <li
        class="nav-profile"
        [ngClass]="{ active: navProfileState == 'expand' }"
        *ngIf="!isLoggedIn"
      >
        <div class="image image-icon bg-white text-grey-darker float-left">
          <img src="/assets/img/anonymous.jpeg" />
        </div>
        <div class="info anonymous-info">
          <div class="context-name current-context">Visitor</div>
          <div>Anonymous User</div>
        </div>
      </li>
    </ul>
    <div class="scrollable-content">
      <!-- TOFIX [style.height]="'calc(100% - ' + fixedInfoBox.offsetHeight + 'px)'" -->
      <!-- begin sidebar nav -->
      <ul class="nav nav-header-top">
        <!-- render menu item -->
        <!-- sidebar menu -->
        <ng-container *ngFor="let menu of menus">
          <ng-container *ngIf="!(isCollapsed && menu.id === 'projects')">
            <ng-template
              [lfxPermissionsAll]="menu.permissions?.all"
              [ngxPermissionsOnly]="menu.permissions?.any"
              [ngxPermissionsExcept]="menu.permissions?.except"
            >
              <li
                [routerLinkActive]="menu.submenu ? 'none' : 'active'"
                #rla1="routerLinkActive"
                [routerLinkActiveOptions]="{ exact: false }"
                [ngClass]="{
                  expand:
                    (isMenuUrlActive(router, menu) && !menu.state) ||
                    menu.state === 'expand',
                  'transparent-bg': menu.title === 'Projects'
                }"
              >
                <a
                  *ngIf="!menu.url"
                  [title]="menu.title || ''"
                  (click)="
                    menu.submenu || menu.autoComplete ? toggleSubmenu(menu) : ''
                  "
                >
                  <ng-container
                    *ngTemplateOutlet="sidebarMenu; context: { menu: menu }"
                  ></ng-container>
                </a>
                <a
                  *ngIf="menu.url && !menu.isExternalUrl"
                  [title]="menu.title || ''"
                  [routerLink]="
                    getCurrentCompanySlug() + menu.url + getMenuDefaultTab(menu)
                  "
                  [queryParams]="menu.queryParams"
                  (click)="
                    menu.submenu || menu.autoComplete
                      ? toggleSubmenu(menu)
                      : '';
                    menu.updateMenu && menu.id ? updateProjectMenu(menu.id) : ''
                  "
                  [ngClass]="{ 'bold-menu': isMenuUrlActive(router, menu) }"
                >
                  <ng-container
                    *ngTemplateOutlet="sidebarMenu; context: { menu: menu }"
                  ></ng-container>
                </a>

                <a
                  *ngIf="menu.url && menu.isExternalUrl"
                  [title]="menu.title || ''"
                  [href]="menu.url"
                  target="_blank"
                  [ngClass]="{ 'bold-menu': isMenuUrlActive(router, menu) }"
                >
                  <ng-container
                    *ngTemplateOutlet="sidebarMenu; context: { menu: menu }"
                  ></ng-container>
                </a>

                <!-- sidebar submenu lvl-1 -->
                <div
                  [class.overflow-visible]="
                    menu.state === 'expand' && menu.autoComplete
                  "
                  [@expandCollapse]="
                    menu.state ? menu.state : expandCollapse(menu, rla1)
                  "
                  *ngIf="menu.submenu || menu.autoComplete"
                >
                  <div class="form-group margin-b-0" *ngIf="menu.autoComplete">
                    <lfx-autocomplete-search
                      [textThreshold]="2"
                      [placeholderText]="'Search'"
                      [usedFor]="'search-project'"
                      (searchInput)="onAutoCompleteSearchInput($event, menu)"
                      [searchResult$]="
                        getAutoCompleteSearchResultObservable(menu)
                      "
                      (selectedItem)="autoCompleteSelection($event, menu)"
                    ></lfx-autocomplete-search>
                  </div>
                  <div
                    class="height-100 position-relative"
                    *ngIf="checkIsLoading(menu)"
                  >
                    <ngx-spinner
                      type="ball-clip-rotate"
                      [fullScreen]="false"
                      [name]="'menu-' + menu.title + '-spinner'"
                      color="rgba(0,0,0,0.5)"
                      bdColor="rgba(0,0,0,0)"
                      size="default"
                    ></ngx-spinner>
                  </div>
                  <ul
                    *ngIf="!checkIsLoading(menu) && menu.submenu"
                    class="sub-menu level-1"
                    [ngStyle]="{
                      'margin-top.px': this.pageSettings.pageSidebarMinified
                        ? -scrollTop
                        : 0
                    }"
                  >
                    <ng-container *ngFor="let sub1 of menu.submenu">
                      <ng-template
                        [lfxPermissionsAll]="sub1.permissions?.all"
                        [ngxPermissionsOnly]="sub1.permissions?.any"
                        [ngxPermissionsExcept]="sub1.permissions?.except"
                      >
                        <li
                          [routerLinkActive]="sub1.submenu ? 'none' : 'active'"
                          #rla2="routerLinkActive"
                          [ngClass]="{
                            expand:
                              (rla2.isActive && !sub1.state) ||
                              sub1.state === 'expand',
                            'last-child': !sub1.submenu,
                            'transparent-bg': !router.url.includes('/project/')
                          }"
                        >
                          <a
                            *ngIf="!sub1.url"
                            (click)="sub1.submenu ? toggleSubmenu(sub1) : ''"
                          >
                            <ng-container
                              *ngTemplateOutlet="
                                sidebarSubMenu;
                                context: { menu: sub1 }
                              "
                            ></ng-container>
                          </a>
                          <a
                            *ngIf="sub1.url && !sub1.isExternalUrl"
                            [routerLink]="getCurrentCompanySlug() + sub1.url"
                            [queryParams]="sub1.queryParams"
                            (click)="
                              sub1.submenu ? toggleSubmenu(sub1) : '';
                              sub1.updateMenu && sub1.id
                                ? updateProjectMenu(sub1.id)
                                : ''
                            "
                          >
                            <ng-container
                              *ngTemplateOutlet="
                                sidebarSubMenu;
                                context: { menu: sub1, parentTitle: menu.title }
                              "
                            >
                            </ng-container>
                          </a>
                          <a
                            *ngIf="sub1.url && sub1.isExternalUrl"
                            [title]="menu.title || ''"
                            [href]="menu.url"
                            target="_blank"
                          >
                            <ng-container
                              *ngTemplateOutlet="
                                sidebarMenu;
                                context: { menu: menu }
                              "
                            ></ng-container>
                          </a>
                          <!-- sidebar submenu lvl-2 -->
                          <div
                            [@expandCollapse]="
                              sub1.state
                                ? sub1.state
                                : expandCollapse(sub1, rla2)
                            "
                            *ngIf="sub1.submenu"
                          >
                            <ul class="sub-menu level-2">
                              <ng-container *ngFor="let sub2 of sub1.submenu">
                                <ng-template
                                  [lfxPermissionsAll]="sub2.permissions?.all"
                                  [ngxPermissionsOnly]="sub2.permissions?.any"
                                  [ngxPermissionsExcept]="
                                    sub2.permissions?.except
                                  "
                                >
                                  <li
                                    [routerLinkActive]="
                                      sub2.submenu ? 'none' : 'active'
                                    "
                                    #rla3="routerLinkActive"
                                    [ngClass]="{
                                      expand: sub2.state == 'expand',
                                      'last-child': !sub2.submenu
                                    }"
                                  >
                                    <a
                                      *ngIf="!sub2.url"
                                      (click)="
                                        sub2.submenu ? toggleSubmenu(sub2) : ''
                                      "
                                    >
                                      <ng-container
                                        *ngTemplateOutlet="
                                          sidebarSubMenu;
                                          context: { menu: sub2 }
                                        "
                                      ></ng-container>
                                    </a>
                                    <a
                                      *ngIf="sub2.url && !sub2.isExternalUrl"
                                      [routerLink]="
                                        getCurrentCompanySlug() + sub2.url
                                      "
                                      [queryParams]="sub2.queryParams"
                                    >
                                      <ng-container
                                        *ngTemplateOutlet="
                                          sidebarSubMenu;
                                          context: {
                                            menu: sub2,
                                            parentTitle: menu.title
                                          }
                                        "
                                      ></ng-container>
                                    </a>
                                    <a
                                      *ngIf="sub2.url && sub2.isExternalUrl"
                                      [title]="menu.title || ''"
                                      [href]="menu.url"
                                      target="_blank"
                                    >
                                      <ng-container
                                        *ngTemplateOutlet="
                                          sidebarMenu;
                                          context: { menu: menu }
                                        "
                                      ></ng-container>
                                    </a>

                                    <!-- sidebar submenu lvl-3 -->
                                    <div
                                      [@expandCollapse]="
                                        sub2.state
                                          ? sub2.state
                                          : rla3.isActive && !sub2.state
                                          ? 'active'
                                          : 'collapse'
                                      "
                                      *ngIf="sub2.submenu"
                                    >
                                      <ul class="sub-menu level-3">
                                        <ng-container
                                          *ngFor="let sub3 of sub2.submenu"
                                        >
                                          <ng-template
                                            [lfxPermissionsAll]="
                                              sub3.permissions?.all
                                            "
                                            [ngxPermissionsOnly]="
                                              sub3.permissions?.any
                                            "
                                            [ngxPermissionsExcept]="
                                              sub3.permissions?.except
                                            "
                                          >
                                            <li
                                              [routerLinkActive]="
                                                sub3.submenu ? 'none' : 'active'
                                              "
                                              #rla4="routerLinkActive"
                                              [ngClass]="{
                                                expand: sub3.state == 'expand',
                                                'last-child': !sub3.submenu
                                              }"
                                            >
                                              <a
                                                *ngIf="!sub3.url"
                                                (click)="
                                                  sub3.submenu
                                                    ? toggleSubmenu(sub3)
                                                    : ''
                                                "
                                              >
                                                <ng-container
                                                  *ngTemplateOutlet="
                                                    sidebarSubMenu;
                                                    context: { menu: sub3 }
                                                  "
                                                >
                                                </ng-container>
                                              </a>
                                              <a
                                                *ngIf="
                                                  sub3.url &&
                                                  !sub3.isExternalUrl
                                                "
                                                [routerLink]="
                                                  getCurrentCompanySlug() +
                                                  sub3.url
                                                "
                                                [queryParams]="sub3.queryParams"
                                              >
                                                <ng-container
                                                  *ngTemplateOutlet="
                                                    sidebarSubMenu;
                                                    context: { menu: sub3 }
                                                  "
                                                >
                                                </ng-container>
                                              </a>
                                              <a
                                                *ngIf="
                                                  sub3.url && sub3.isExternalUrl
                                                "
                                                [title]="menu.title || ''"
                                                [href]="menu.url"
                                                target="_blank"
                                              >
                                                <ng-container
                                                  *ngTemplateOutlet="
                                                    sidebarMenu;
                                                    context: { menu: menu }
                                                  "
                                                ></ng-container>
                                              </a>
                                            </li>
                                          </ng-template>
                                        </ng-container>
                                      </ul>
                                    </div>
                                  </li>
                                </ng-template>
                              </ng-container>
                            </ul>
                          </div>
                        </li>
                      </ng-template>
                    </ng-container>
                  </ul>
                </div>
              </li>
              <div
                class="horizontal-line"
                *ngIf="menu.url && menu.url === separatorPositionAfterUrl"
              ></div>
            </ng-template>
          </ng-container>
        </ng-container>

        <!-- begin sidebar minify button -->
        <!-- <li><a href="javascript:;" class="sidebar-minify-btn" (click)="toggleMinified()"><i
              class="fas fa-angle-double-left"></i></a></li> -->
        <!-- end sidebar minify button -->
      </ul>
    </div>
    <!-- end sidebar nav -->
  </ng-template>
  <!-- <div class="collapse-expand-btn" (click)="toggleCollapse()">
      <i class="fas fa-chevron-double-left" *ngIf="!isCollapsed"></i>
      <i class="fas fa-chevron-double-right" *ngIf="isCollapsed"></i>
    </div> -->
  <ng-container *ngIf="!isCollapsed">
    <ng-template [lfxPermissionsAll]="'feature_CompanyDashboard-Redirect'">
      <div class="version-switcher">
        <div>Looking for the previous version?</div>
        <span class="lf-link" (click)="onSwitchVersion()">Click Here</span>
      </div>
    </ng-template>
  </ng-container>
</div>
<!-- end #sidebar -->

<ng-template #sidebarMenu let-menu="menu">
  <span class="badge pull-right" *ngIf="menu.badge">{{ menu.badge }}</span>
  <div class="icon-img" *ngIf="menu.img"><img src="{{ menu.img }}" /></div>
  <i
    class="menu-icon {{
      (isMenuUrlActive(router, menu) && !menu.state) || menu.state === 'expand'
        ? menu.selectedIcon
        : menu.icon
    }}"
    *ngIf="menu.icon"
  ></i>
  <span
    class="menu-title"
    *ngIf="menu.title && !isCollapsed"
    [ngClass]="{ 'title-width': !minified }"
  >
    {{ menu.title }}
    <i
      class="fas fa-info-circle tooltip-icon"
      *ngIf="menu.toolTipText && menu.state === 'expand'"
      [ngbPopover]="menu.toolTipText"
      triggers="mouseenter:mouseleave"
      popoverClass="tooltip-popover"
      placement="right"
      container="body"
    ></i>
    <span class="label label-theme m-l-5" *ngIf="menu.label">{{
      menu.label
    }}</span>
  </span>
  <i
    class="suffix-icon {{ menu.suffixIcon }}"
    *ngIf="menu.suffixIcon && !isCollapsed"
  ></i>
  <b
    class="fas fa-angle-right menu-arrow"
    [class.expanded]="menu.state === 'expand'"
    *ngIf="menu.caret && !isCollapsed"
  ></b>
  <span class="lf-beta-badge" *ngIf="menu.beta && !isCollapsed">Beta</span>
</ng-template>

<!-- render submenu item -->
<ng-template #sidebarSubMenu let-menu="menu">
  <i class="fas fa-star" *ngIf="menu.favorite"></i>
  <b
    class="fas fa-angle-right menu-arrow mt-1"
    [class.expanded]="menu.state === 'expand'"
    *ngIf="menu.caret"
  ></b>
  <b
    ngbDropdown
    #tooltipMenu="ngbDropdown"
    class="d-inline-block tooltip-menu-wrapper"
    container="body"
    placement="right-top right-bottom"
  >
    <span
      ngbDropdownAnchor
      (mouseenter)="tooltipMenu.toggle()"
      class="menu-tooltip-text"
      *ngIf="menu.hoverList && menu.hoverList.text"
      >{{ menu.hoverList.text }}</span
    >
    <span ngbDropdownMenu class="tooltip-menu">
      <ng-container *ngIf="menu.hoverList && menu.hoverList.list">
        <ng-container
          *ngTemplateOutlet="
            tooltipList;
            context: { list: menu.hoverList.list }
          "
        ></ng-container>
      </ng-container>
    </span>
  </b>
  <i class="menu-icon {{ menu.icon }}" *ngIf="menu.icon"></i>
  <span
    class="menu-title"
    [title]="menu.title || ''"
    [ngClass]="{ 'menu-title-margin': !menu.icon && !menu.submenu }"
  >
    {{ menu.title }}
  </span>
  <i class="fas fa-paper-plane text-theme m-l-5" *ngIf="menu.highlight"></i>
</ng-template>

<ng-template #tooltipList let-list="list">
  <div
    class="sidebar-tooltip-list-wrapper"
    (click)="$event.stopPropagation()"
    *ngIf="{ showMore: true, slice: 7 }; let localVars"
  >
    <div>
      <a
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
        class="tooltip-list-title"
        #titleLink="routerLinkActive"
        [title]="list.parent.title"
        [routerLink]="list.parent.url"
        (click)="!titleLink.isActive ? updateProjectMenu(list.parent.id) : ''"
      >
        {{ list.parent.title }}
      </a>
      <ul class="mb-0">
        <li
          class="tooltip-list-item"
          [title]="item.title"
          *ngFor="let item of list.list | slice: 0:localVars.slice"
        >
          <a
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="item.url"
            #itemLink="routerLinkActive"
            (click)="!itemLink.isActive ? updateProjectMenu(item.id) : ''"
          >
            {{ item.title }}
          </a>
        </li>
        <li
          class="show-more-btn"
          *ngIf="localVars.showMore && list.list.length > localVars.slice"
        >
          <span
            class="action-link cursor-pointer"
            (click)="
              localVars.slice = list.list.length; localVars.showMore = false
            "
          >
            Show More...
          </span>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
